import { mapState } from 'vuex';
import {UtilLib as ProUtilLib} from '@/libs/index.js'

export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            theme: state => state.comp.theme,
        }),
    },
    methods: {
        themeMainRgba(opacity = 1) {
            return ProUtilLib.hexToRgba(this.theme.main, opacity).rgba;
        },
    },
}