import {message} from 'ant-design-vue'
import axios from 'axios';
import Event, {EventEnum} from '@/EventEmeitter'

export class FsLibs {
	
	static saveFile(url, fileName) {
		const _that = this;
		if (!fileName) {
			message.error('fileName不能为空')
			return false;
		}
		fileName = fileName.replaceAll('/', '-');
		fileName = fileName.replaceAll("\\", "＼");
		fileName = fileName.replaceAll("/", "╱");
		fileName = fileName.replaceAll(":", "：");
		fileName = fileName.replaceAll("*", "×");
		fileName = fileName.replaceAll("?", "？");
		fileName = fileName.replaceAll("\"", "”");
		fileName = fileName.replaceAll("<", "＜");
		fileName = fileName.replaceAll(">", "＞");
		fileName = fileName.replaceAll("|", "丨");
		
        axios({
            method: 'get',
            url,
            responseType: 'blob',
            onDownloadProgress: (evt) => {
                // const num = parseInt((evt.loaded / evt.total) * 100);
                // console.log(num);
            },
        }).then((res) => {
            Event.emit(EventEnum.PROGRESS_CLOSE);
            const blob = new Blob([res.data]); //处理文档流
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        }).catch((err) => {
            Event.emit(EventEnum.PROGRESS_CLOSE);
            message.error('文件下载失败，请稍后再试')
        })
	}
	
}
