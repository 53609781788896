<template>
	<div class="yq-record-generate">
		<div class="generate-suspend" :class="show ? 'hide' : ''" v-if="minWindowTask" @click="open">
			<div class="icon-view flex-center">
				<div class="loading-status flex-center" v-if="minWindowTask.status == 'loading'">
					<div class="max-set"><a-icon type="setting" :style="{ color: '#3B54FB', fontSize: '22px' }" /></div>
					<div class="mini-set"><a-icon type="setting" :style="{ color: '#3B54FB', fontSize: '16px' }" /></div>
				</div>
				<img :src="`${staticHost}/icon/status-wait.png`" v-if="minWindowTask.status == 'wait'" />
				<img :src="`${staticHost}/icon/status-success.png`" v-if="minWindowTask.status == 'success'" />
				<img :src="`${staticHost}/icon/status-error.png`" v-if="minWindowTask.status == 'error'" />
				<img :src="`${staticHost}/icon/status-error.png`" v-if="minWindowTask.status == 'timeout'" />
			</div>
			<div class="text-view flex-center">{{ statusObj[minWindowTask.status] }}</div>
		</div>

		<a-modal
			:title="$t('文件生成')"
			:visible="show"
			:body-style="{ padding: 0 }"
			@cancel="close"
		>
			<generate-back ref="generateBackRef" @putAway="close"></generate-back>
			<template slot="footer">
				<a-button type="primary" ghost @click="close" class="putAwayButton">
					<div class="loading-status flex-center">
                        <div class="max-set flex-center"><a-icon type="setting" :style="{ fontSize: '18px' }" /></div>
					    <div class="mini-set flex-center"><a-icon type="setting" :style="{ fontSize: '12px' }" /></div>
                    </div>
					<span>{{ $t('button.putAway') }}</span>
				</a-button>
			</template>
		</a-modal>
		
	</div>
</template>

<script>
	import { mapState, mapGetters } from "vuex";
	import {GenerateStatus,AppConfig} from '../../libs/index.js'
	
	import GenerateBack from './generate-back.vue'
	
	export default {
		data() {
			return {
				staticHost: AppConfig.staticHost,
				
				show: false,
				// show: true,
			}
		},
		components: {GenerateBack},
		watch: {
			taskList: function(val) {
				if (val.length <= 0) {
					this.close();
				}
			}
		},
		computed: {
			...mapState({
				taskList: state => state.recordGenerate.taskList,
			}),
			...mapGetters({
				minWindowTask: 'recordGenerate/minWindowTask',
			}),
			statusObj() {
				return {
					[GenerateStatus.WAIT]: this.$t('system.wait'),
					[GenerateStatus.LOADING]: this.$t('file.generate'),
					[GenerateStatus.SUCCESS]: this.$t('file.generateSuccess'),
					[GenerateStatus.ERROR]: this.$t('system.error'),
					[GenerateStatus.TIMEOUT]: this.$t('system.timeout'),
				}
			},
		},
		methods: {
			open() {
				this.show = true;
			},
			close() {
				this.show = false;
			},
		}
	}
</script>

<style lang="less" scoped>
	.generate-suspend{
		position: fixed;
		right: 11px;
		top: 50%;
		z-index: 14;
		margin-top: -35px;
		width: 50px;
		height: 80px;
		background-color: #ffffff;
		border-radius: 25px 25px 8px 8px;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
		transition: all 0.6s;
		transform: translateX(0);
		cursor: pointer;
		&.hide{
			transform: translateX(110px);
		}
		.icon-view{
			width: 50px;
			height: 50px;
			border-radius: 50px;
			background-color: #ffffff;
			box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
		}
		img{
			width: 26px;
			height: 26px;
		}
		.text-view{
			width: 100%;
			height: 28px;
			color: @main-color;
			font-size: 12px;
			font-weight: bold;
		}
		.loading-status div{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.max-set{
			-webkit-animation:changeright 4s linear infinite;
		}
		.mini-set{
			-webkit-animation:changeleft 4s linear infinite;
		}
	}

	.putAwayButton{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .loading-status{
            margin-right: 6px;
        }
        .flex-center{
            height: 100%;
        }
        .max-set{
			-webkit-animation:changeright 4s linear infinite;
		}
		.mini-set{
			-webkit-animation:changeleft 4s linear infinite;
		}
    }
	
	@keyframes changeright{       
	        
	    0%{-webkit-transform:rotate(0deg);}
	
	    50%{-webkit-transform:rotate(180deg);}
	
	    100%{-webkit-transform:rotate(360deg);}
	}
	@keyframes changeleft{       
	    
	    0%{-webkit-transform:rotate(0deg);}
	
	    50%{-webkit-transform:rotate(-180deg);}
	
	    100%{-webkit-transform:rotate(-360deg);}
	}
</style>