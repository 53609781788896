
import { message } from 'ant-design-vue'
import {StorageLib,StorageKey,LectoConfig,UtilLib} from '@/libs'

function locationHref(url, replace = false) {
	if (replace) {
		window.location.replace(url);
	} else {
		window.location.href = url;
	}
}

const cidArrs = [2, 3, 20,22,30,31];

export class PageLib {

	static lectoPageUrlReset() {
		const resetUrl = UtilLib.delurlparam(location.href, 'urlParams');
		history.replaceState(null, '', resetUrl);
	}

	static lectoPageChangeUrl(filters = []) {
		const initParams = StorageLib.sessionGet(StorageKey.PARAMS_LECTO_DATA) || {};
		initParams.source = 'share';
		let filterParams = filters.map(item => ({ paramName: item.paramName, value: item.value }));
		(initParams.filterParams || []).forEach(item => {
			const find = filterParams.find(v => v.paramName == item.paramName);
			if (!find) {
				filterParams.push({ paramName: item.paramName, value: item.value })
			}
		})
		initParams.filterParams = filterParams;

		const resetUrl = UtilLib.delurlparam(location.href, 'urlParams');
		const urlStr = resetUrl + (resetUrl.includes('?') ? '&' : '?') + 'urlParams=' + encodeURIComponent(JSON.stringify(initParams));
		history.replaceState(null, '', urlStr);
	}
	
	/**
	 * 参数选型页面
	 * @param {*} cid 系列id
	 * @param {*} urlParams 
	 * @param {*} isDev 
	 * @returns 
	 */
	static goLectoPage(cid, urlParams = {}, replace = false) {
		const numberCid = Number(cid);
		if (!numberCid) {
			message.error('cid不能为空');
			return false;
		}
		if ((numberCid < 100 || numberCid > 200) && numberCid < 10000 && !LectoConfig.paramsWhite.includes(numberCid)) {
			message.info('开发中...');
			return false;
		}
		StorageLib.sessionSet(StorageKey.PARAMS_LECTO_DATA, urlParams);
		
		// 参数选型可反选、强制选的页面
		let isReAllow = false;
		/**
		 * 20：滑台模组
		 * 23：新电动缸
		 * 27：三代电缸
		 * 28：直线电机模组
		 * 101:四大系列减速机
		 * 104：行星减速机
		 * 120：转台
		 * 130：精密换向器
		 * 131：换向器
		 * 300：新的升降机
		 */
		const reAllowCids = [20,23,27,28,33,35,101,104,120,130,131,300];
		(reAllowCids.includes(Number(cid))) && (isReAllow = true);
		// 减速机（不包含行星减速机）
		(cid >= 100 && cid < 120 && cid != 104) && (isReAllow = true);

		locationHref(`/#/${isReAllow ? 'productslecto' : 'paramslecto'}/${cid}`, replace);
	}

	/**
	 * 跳转到产品详情页
	 * @param {*} fid 
	 * @param {*} filterParams 
	 * @param {*} tab 
	 * @param {*} replace 
	 */
	static goProductDetail(fid, params = {}, replace = false) {
		let data = {};
		params.cid && (data.cid = params.cid);
		params.tab !== undefined && (data.tab = params.tab);
		params.filterParams && (data.filterParams = params.filterParams);
		StorageLib.sessionSet(StorageKey.PRODUCT_DETAIL_PARAMS, data)
		const url = `/#/selection/detail/${fid}`;
		locationHref(url, replace);
	}

	/**
	 * 跳转到系列目录列表页（产品选型）
	 * @param {*} cid 系列id
	 * @param {*} replace 
	 */
	static goSeriesList(cid, replace = false) {
		const url = `/#/selection/serieslist/${cid}`;
		locationHref(url, replace);
	}

	/**
	 * 开始工况选型
	 * @param {*} cid 系列id
	 */
	static goCalc(cid, step = 1, replace = false, render = 1) {
		if (!cidArrs.includes(Number(cid))) {
			message.info('开发中...');
			return false;
		}
		if (Number(cid) === 4) {
			this.goLectoPage(cid);
			return false;
		}
		const url = `/#/calculation/calc/${step}/${cid}?render=${render}`;
		locationHref(url, replace);
	}

	/**
	 * 选型详情页面
	 * @param {*} selectionId 选型详情id
	 * @param {*} source 来源
	 * @param {*} replace 
	 */
	static goSelectionConfirm(selectionId, source = 'list', replace = false) {
		const type = location.href.includes('selection') ? 'selection' : 'calculation'
		const url = `/#/${type}/confirm/${selectionId}?source=${source}`;
		locationHref(url, replace);
	}
	
}
