import i18n from '@/locale/index.js'
import {message} from 'ant-design-vue'
import {NetRecordDownloadService} from '@yqmodel/module'
import {GenerateStatus,GenerateEvent,GeneratePopup,StorageLib,StorageKey} from '@/libs/index.js'
import Event from '@/EventEmeitter'

// 定时检查生成状态
let checkModelInterval = null;
// 生成超时监听
let createModelTimeOut = null;
// 当前进行中的任务
let nowTask = null;

function TaskStorageData() {
	return StorageLib.get(StorageKey.GENERATE_DATA) || [];
}
function TaskStorageSet(taskList) {
	taskList = taskList.filter(item => item.downloadType != 4);
	StorageLib.set(StorageKey.GENERATE_DATA, taskList)
	return TaskStorageData();
}

const RecordGenerateModule = {
	namespaced: true,
	state: {
		/**
		 * 生成任务进程
		 * { taskid, skuName, title, selectionId, downloadType, insert3d, orderParams, status }
		 */
		// taskList: [],
		taskList: [
			// {"taskid":"uknNupciyJ1bMc1s3HRyr3aONzd2HIhH","status":"success","title":"订货单","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"产品订货单","button":"PDF下载","key":6},"has3D":false,"downloadType":6,"isEnabled":2,"contentLength":"0","checkPath":"\\class\\104\\series\\10401\\orders\\1656895996199936_0.pdf"},
			// {"taskid":"uE6TdnPh6R2UUC8YsuxLnwKxnaY67nVM","status":"loading","title":"ZIP文件","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"下载到微信","button":"下载到微信","key":3,"hide":true},"downloadType":3,"isEnabled":2,"contentLength":"0"},
			// {"taskid":"uE6TdnPh6R2UUC8YsuxLnwKxnaY67nVM1","status":"error","title":"ZIP文件","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"下载到微信","button":"下载到微信","key":3,"hide":true},"downloadType":3,"isEnabled":2,"contentLength":"0"},
			// {"taskid":"uE6TdnPh6R2UUC8YsuxLnwKxnaY67nVM2","status":"timeout","title":"ZIP文件","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"下载到微信","button":"下载到微信","key":3,"hide":true},"downloadType":3,"isEnabled":2,"contentLength":"0"},
			// {"taskid":"uE6TdnPh6R2UUC8YsuxLnwKxnaY67nVM3","status":"wait","title":"ZIP文件","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"下载到微信","button":"下载到微信","key":3,"hide":true},"downloadType":3,"isEnabled":2,"contentLength":"0"},
			// {"taskid":"uE6TdnPh6R2UUC8YsuxLnwKxnaY67nVM4","status":"wait","title":"ZIP文件","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"下载到微信","button":"下载到微信","key":3,"hide":true},"downloadType":3,"isEnabled":2,"contentLength":"0"},
			// {"taskid":"uE6TdnPh6R2UUC8YsuxLnwKxnaY67nVM5","status":"wait","title":"ZIP文件","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"下载到微信","button":"下载到微信","key":3,"hide":true},"downloadType":3,"isEnabled":2,"contentLength":"0"},
			// {"taskid":"uE6TdnPh6R2UUC8YsuxLnwKxnaY67nVM6","status":"wait","title":"ZIP文件","skuName":"HLR120-L1-4-S2-P2/SL23","selectionId":"1656895996199936","insert3d":false,"orderParams":[],"button":{"label":"下载到微信","button":"下载到微信","key":3,"hide":true},"downloadType":3,"isEnabled":2,"contentLength":"0"},
		],
		popupStatus: GeneratePopup.BACKGROUNP,
	},
	getters: {
		popupIsShow(state) {
			return state.popupStatus == GeneratePopup.FRONTEND
		},
		// 是否还有正在进行的任务
		hasTasking(state) {
			return state.taskList.find(item => [GenerateStatus.WAIT, GenerateStatus.LOADING].includes(item.status))
		},
		firstTask(state) {
			if (!state.taskList[0]) return null;
			return state.taskList[0];
		},
		lastTask(state) {
			if (!state.taskList[state.taskList.length - 1]) return null;
			return state.taskList[state.taskList.length - 1];
		},
		minWindowTask(state) {
			const taskList = state.taskList.filter(item => item.downloadType != 4);
			const loadingTask = taskList.find(item => item.status == GenerateStatus.LOADING);
			if (loadingTask) return loadingTask;
			const successTask = taskList.find(item => item.status == GenerateStatus.SUCCESS);
			if (successTask) return successTask;
			const timeoutTask = taskList.find(item => item.status == GenerateStatus.TIMEOUT);
			if (timeoutTask) return timeoutTask;
			const errorTask = taskList.find(item => item.status == GenerateStatus.ERROR);
			if (errorTask) return errorTask;
			if (!taskList[taskList.length - 1]) return null;
			return taskList[taskList.length - 1];
		}
	},
	mutations: {
		taskSet(state, taskList) {
			state.taskList = JSON.parse(JSON.stringify(taskList));
			TaskStorageSet(state.taskList);
		},
		taskAdd(state, data) {
			const taskList = JSON.parse(JSON.stringify(state.taskList.concat([data])));
			state.taskList = taskList;
			TaskStorageSet(state.taskList);
		},
		taskDelete(state, taskid) {
			state.taskList = state.taskList.filter(item => item.taskid != taskid);
			TaskStorageSet(state.taskList);
		},
		taskDataChange(state, payload) {
			const findIndex = state.taskList.findIndex(item => item.taskid == payload.taskid);
			if (findIndex < 0) return;
			Object.keys(payload).forEach(item => {
				state.taskList[findIndex][item] = payload[item];
			})
			TaskStorageSet(state.taskList);
		},
		// 任务进程前台展示
		generateF(state) {
			state.popupStatus = GeneratePopup.FRONTEND
		},
		// 任务进程后台展示
		generateB(state) {
			state.popupStatus = GeneratePopup.BACKGROUNP
		},
	},
	actions: {
		InitTask(context) {
			context.commit('taskSet', TaskStorageData().map(item => ({ ...item, status: item.status == GenerateStatus.LOADING ? GenerateStatus.WAIT : item.status })));
			context.dispatch('taskCreateModel')
		},
		/**
		 * 添加一个生成任务
		 * task: { title, skuName, selectionId, insert3d, contentLength, downloadType, isEnabled, previewType, previewUrl, orderParams }
		 */
		async taskAdd(context, option) {
			const checkHas = context.state.taskList.find(item => (item.selectionId == option.selectionId && item.downloadType == option.downloadType));
			const hasTasking = !!(context.state.taskList.find(item => [GenerateStatus.WAIT, GenerateStatus.LOADING].includes(item.status)))
			if (checkHas) {
				message.info(i18n.t('file.generate') + '...');
				return false;
			}
			const task = {
				taskid: `yqpcdown${new Date().valueOf()}`,
				status: GenerateStatus.WAIT,
				...option,
			};
			context.commit('taskAdd', task);
			
			context.dispatch('generateChange', {
				status: GenerateStatus.WAIT,
				title: i18n.t('file.generateReady'),
				task
			});
			if (!hasTasking) {
				context.dispatch('taskCreateModel')
			} else if (option.downloadType == 4) {
				context.dispatch('previewFirst')
			}
			return task;
		},

		// brep预览任务优先
		async previewFirst({dispatch,commit}) {
			if (!nowTask) return;
			// 定时任务停止
			dispatch('stopCheckModel');
			dispatch('generateChange', {
				status: GenerateStatus.WAIT,
				title: i18n.t('file.generateReady'),
				task: nowTask,
			});
			commit('taskDataChange', { taskid: nowTask.taskid, status: GenerateStatus.WAIT });
			dispatch('taskCreateModel')
		},
		
		// 任务重试
		async taskRetry({dispatch,state}, taskid) {
			const findIndex = state.taskList.findIndex(item => item.taskid == taskid);
			if (findIndex < 0) return;
			
			dispatch('generateChange', {
				status: GenerateStatus.WAIT,
				title: i18n.t('file.generateReady'),
				task: state.taskList.find(item => item.taskid == taskid)
			});
			
			const hasTasking = !!(state.taskList.find(item => [GenerateStatus.LOADING].includes(item.status)))
			if (!hasTasking) {
				setTimeout(() => {
					dispatch('taskCreateModel')
				}, 400)
			}
		},
		// 删除一条任务
		async taskRemove({dispatch,commit}, taskid) {
			if (nowTask && taskid == nowTask.taskid) {
				// 定时任务停止
				dispatch('stopCheckModel');
				// 删除任务
				commit('taskDelete', taskid);
				// 开启下一条任务
				dispatch('taskCreateModel');
				return false;
			}
			commit('taskDelete', taskid);
		},
		// 单个任务开始
		async taskCreateModel({ state, dispatch }) {
			nowTask = state.taskList.find(item => (item.status == GenerateStatus.WAIT && item.downloadType == 4));
			!nowTask && (nowTask = state.taskList.find(item => item.status == GenerateStatus.WAIT));
			if (!nowTask) return;
			let {title,selectionId,downloadType,orderParams,insert3d} = nowTask;
			dispatch('generateChange', {
				status: GenerateStatus.LOADING,
				title: i18n.t('file.generateName', { name: title }),
				task: nowTask,
			});
			const res = await NetRecordDownloadService.CreateModel(
				selectionId,
				downloadType,
				orderParams,
				insert3d
			);
			
			if (res.code === 0) {
				// 提示文案修改
				title == '3D-CAD' && (title = '3D models');
				
				nowTask.checkPath = res.data.checkPath;
				
				// 开始定时检查
				dispatch('startCheckModel');
				// 监听超时
				dispatch('createModelTimeOutRemove');
				dispatch('createModelTimeOutAdd');
			} else {
				dispatch('generateChange', {
					status: GenerateStatus.ERROR,
					title: i18n.t('file.generateName', { name: title }),
					task: nowTask,
				});
				// 开始下一条任务
				dispatch('taskCreateModel');
			}
		},
		// 检查生成状态
		async CheckDownload({dispatch, commit}) {
			if (!nowTask) return;
			let {taskid, checkPath, selectionId, downloadType, orderParams, insert3d} = nowTask;
			const res = await NetRecordDownloadService.CheckCreateModel(checkPath);
			if (res.data.complete) {
				const checkRes = await NetRecordDownloadService.CheckDownload(selectionId, orderParams, insert3d);
				const findNow = (checkRes.data || []).find(item => item.downloadType == downloadType);
				commit('taskDataChange', { taskid, ...findNow });
				
				dispatch('stopCheckModel');

				dispatch('generateChange', {
					status: GenerateStatus.SUCCESS,
					title: i18n.t('file.generateSuccess'),
					task: nowTask,
					checkDatas: checkRes.data || [],
				});
				
				// 开始下一条任务
				dispatch('taskCreateModel');
			} else if(res.code != 0) {
				dispatch('stopCheckModel');
				dispatch('generateChange', {
					status: GenerateStatus.ERROR,
					title: i18n.t('file.generateError'),
					task: nowTask,
				});
				dispatch('taskCreateModel');
			}
		},
		
		
		// 任务进程状态变化
		async generateChange({commit,state}, { status, title, task, checkDatas = null }) {
			const find = state.taskList.find(item => item.taskid == task.taskid);
			if (!find) return;
            Event.emit(GenerateEvent.CHANGE, status, {
				title,
				task,
				checkDatas,
			})
			commit('taskDataChange', { taskid: task.taskid, status });
		},
		
		
		// 定时检查生成状态
		// 开始
		async startCheckModel({dispatch}) {
			checkModelInterval = setInterval(() => {
				dispatch('CheckDownload');
			}, 4000)
		},
		// 停止检查
		async stopCheckModel({dispatch}) {
			dispatch('createModelTimeOutRemove');
			checkModelInterval && (clearInterval(checkModelInterval));
		},
		
		
		// 任务生成超时
		// 移除超时
		createModelTimeOutRemove() {
			createModelTimeOut && clearTimeout(createModelTimeOut);
		},
		// 添加超时
		createModelTimeOutAdd({dispatch}) {
			let {downloadType,insert3d} = nowTask;
			const outTime = (downloadType == 6 && insert3d) ? 150000 : 90000
			createModelTimeOut = setTimeout(() => {
				dispatch('stopCheckModel');
				
				dispatch('generateChange', {
					status: GenerateStatus.TIMEOUT,
					title: i18n.t('file.generateTimeOut', { name: nowTask.title }),
					task: nowTask,
				});
				
				// 开始下一条任务
				dispatch('taskCreateModel');
			}, outTime);
		},
	}
};
export default RecordGenerateModule;
