<template>
    <div class="header-view" :style="{ background: theme.main }">
        <div class="header-center view-w-max">
            <!-- 左侧logo -->
            <a href="/">
                <img :src="logo" alt="" class="icon-img">
            </a>
            <!-- 头部右侧 -->

            <div class="header-right">

                <locale-switch v-if="!isDesktop"></locale-switch>
                
                <div class="user-login" v-if="isLogin && !isDesktop">
                    <a-avatar :size="28" :src="UserIcon" />
                    <span>{{userInfo.phoneNumber.substr(0,3)}}*****{{userInfo.phoneNumber.substr(-4)}}</span>
                </div>
                <div class="user-notlogin" v-else-if="!isDesktop">
                    <span class="login-btn" @click="userLogin">{{ $t('login.button') }}</span>
                </div>

            </div>

            <!-- <div class="desktop-editview" v-if="isDesktop">
                <div class="minus noDrag"><a-icon type="minus" /></div>
                <div class="border noDrag"><a-icon type="border" /></div>
                <div class="close noDrag"><a-icon type="close" /></div>
            </div> -->

        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import {Modal} from 'ant-design-vue'
    import {UtilLib,isDesktop} from '@yqmodel/module'
    import Event, {EventEnum} from '@/EventEmeitter'

    import UserIcon from '@/assets/icon/user.png'

    import LocaleSwitch from './locale-switch.vue'

    export default{
        props: {
            compDetail: { default: {} }
        },
        data() {
            return {
                UserIcon,
                isDesktop
            }
        },
        components: {LocaleSwitch},
        computed: {
            ...mapState({
                userInfo:state => state.user.userInfo,
                theme: state => state.comp.theme,
            }),
            ...mapGetters({
                isLogin:'user/isLogin',
            }),
            logo() {
                return UtilLib.nodeImgUrl(this.compDetail.logo);
            }
        },
        methods: {
            ...mapActions({
                logOut: 'user/logOut'
            }),
            userLogin() {
                Event.emit(EventEnum.LOGIN_OPEN);
            },
            userLogOut() {
                Modal.confirm({
                    title: '提示',
                    content: '确定退出登录吗？',
                    onOk: () => {
                        this.logOut();
                    },
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    .header-view{
        width: 100%;
        height: 48px;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        padding: 0 30px;
    }
    .header-center{
        height: 100%;
        position: relative;
    }
    .icon-img{
        height: 38px;
        position: absolute;
        left: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .header-right{
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        color: #ffffff;
        /deep/ .ant-avatar{
            margin-right: 6px;
        }
    }
    .desktop-editview{
        height: 100%;
        position: absolute;
        right: -20px;
        top: 0;
        display: flex;
        align-items: center;
        color: #ffffff;
        &>div{
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            margin-left: 14px;
        }
        &>div:hover{
            opacity: 0.6;
        }
    }
    .login-btn{
        cursor: pointer;
    }
</style>
