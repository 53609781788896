<template>
    <a-modal
      :visible="visible"
      class="com-image-view"
      @cancel="close"
      :footer="null"
      width="720px"
      :destroyOnClose="true"
    >
        <div class="swiper-view com-image-swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in list" :key="index">
                    <img :src="item" alt="">
                </div>
            </div>
            <div class="swiper-button-next">
                <a-icon type="right" />
            </div>
            <div class="swiper-button-prev">
                <a-icon type="left" />
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </a-modal>
</template>

<script>
import Event, {EventEnum} from '@/EventEmeitter'

export default{
    data() {
        return {
            visible: false,
            list: []
        }
    },
    mounted() {
        Event.remove(EventEnum.IMAGE_VIEW_OPEN, this.open);
        Event.on(EventEnum.IMAGE_VIEW_OPEN, this.open);
    },
    methods: {
        open(urls = [], index) {
            this.list = urls;
            this.visible = true;
            setTimeout(() => {
                var swiper = new Swiper(".com-image-swiper", {
                    initialSlide: index,
                    pagination: {
                        el: ".swiper-pagination",
                        type: "fraction",
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                });
            }, 600)
        },
        close() {
            this.visible = false;
        }
    }
}
</script>

<style lang="less">
.com-image-view{
    .swiper-view{
        width: 670px;
        height: 590px;
        position: relative;
        overflow: hidden;
        img{
            max-width: 670px;
            max-height: 590px;
        }
    }
    .swiper-pagination{
        height: 20px;
        color: #ffffff;
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        right: 28px;
        top: 0;
        left: inherit;
        width: 42px;
        border-radius: 20px;
        background: rgba(6, 26, 35, 0.2);
        user-select: none;
    }
    .swiper-slide{
        text-align: center;
        img{
            user-select: none;
        }
    }
    .swiper-button-next{
        right: 0!important;
    }
    .swiper-button-prev{
        left: 0!important;
    }
    .swiper-view .swiper-button-next, .swiper-view .swiper-button-prev{
        font-size: 26px;
        color: #60708F;
    }
    .swiper-view .swiper-button-next:after, .swiper-view .swiper-button-prev:after{
        display: none;
    }
}
</style>
