import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import DefaultLayout from "@/layouts/default/index.vue";

const routes = [
    {
        path: "/",
		redirect: "/",
		component: DefaultLayout,
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('@/views/home/index.vue'),
                meta: { title: "首页" },
            },
            {
                path: '/serieslist/:rid',
                name: 'serieslist',
                component: () => import('@/views/series/list/index.vue'),
                meta: { title: "产品系列" },
            },
            {
                path: '/lectoscheme',
                name: 'lectoscheme',
                component: () => import('@/views/lecto-scheme/list/index.vue'),
                meta: { title: "解决方案选型" },
            },
            {
                path: '/lectoscheme/:cid',
                name: 'lectoschemestart',
                component: () => import('@/views/lecto-scheme/lecto/index.vue'),
                meta: { title: "解决方案选型" },
            },
            {
                path: '/selection',
                name: 'selection',
                component: () => import('@/views/selection-home/index.vue'),
                meta: { title: "选型" },
                children: [
                    {
                        path: '/selection/params',
                        name: 'selectionParams',
                        component: () => import('@/views/selection-home/params-list/index.vue'),
                        meta: { title: "产品选型" },
                    },
                    {
                        path: '/selection',
                        name: 'selection',
                        // redirect: "/selection/default",
                        component: () => import('@/views/selection-home/calc-list/index.vue'),
                        meta: { title: "工况选型" },
                        children: [
                            {
                                path: '/selection/default',
                                name: 'selectionDefault',
                                component: () => import('@/views/calculation-lecto/default.vue'),
                                meta: { title: "工况选型（开发中）" },
                            },
                            {
                                path: '/selection/calc_3',
                                name: 'selectionScrewLiftCalc',
                                component: () => import('@/views/calculation-lecto/screw-lift/index.vue'),
                                meta: { title: "升降机-工况选型" },
                            },
                            {
                                path: '/selection/calc_300',
                                name: 'selectionLiftCalc',
                                component: () => import('@/views/calculation-lecto/lift/index.vue'),
                                meta: { title: "升降机-工况选型" },
                            },
                            {
                                path: '/selection/calc_20',
                                name: 'selectionModuleCalc',
                                component: () => import('@/views/calculation-lecto/module/index.vue'),
                                meta: { title: "模组-工况选型" },
                            },
                            {
                                path: '/selection/calc_30',
                                name: 'selectionBallScrewCalc',
                                component: () => import('@/views/calculation-lecto/ball-screw/index.vue'),
                                meta: { title: "丝杆-工况选型" },
                            },
                            {
                                path: '/selection/calc_31',
                                name: 'selectionBallRailCalc',
                                component: () => import('@/views/calculation-lecto/ball-rail/index.vue'),
                                meta: { title: "导轨-工况选型" },
                            },
                            {
                                path: '/selection/calc_2000000',
                                name: 'selectionThreeCalc',
                                component: () => import('@/views/calculation-lecto/three-calculate/index.vue'),
                                meta: { title: "齿轮减速机（配异步电机）" },
                            },
                            {
                                path: '/selection/calc_2000002',
                                name: 'selectionThreeCalc',
                                component: () => import('@/views/calculation-lecto/three-calculate/index.vue'),
                                meta: { title: "齿轮减速机（手摇）" },
                            },
                            {
                                path: '/selection/calc_2000001',
                                name: 'selectionThreeCalc',
                                component: () => import('@/views/calculation-lecto/servo-calculate/index.vue'),
                                meta: { title: "齿轮减速机（配伺服电机）" },
                            },
                            {
                                path: '/selection/calc_22',
                                name: 'electricCylinder22',
                                component: () => import('@/views/calculation-lecto/electric-cylinder/index.vue'),
                                meta: { title: "电动缸 - 工况选型" },
                            },
                            {
                                path: '/selection/calc_23',
                                name: 'electricCylinder23',
                                component: () => import('@/views/calculation-lecto/electric-cylinder/index.vue'),
                                meta: { title: "电动缸 - 工况选型" },
                            },
                            {
                                path: '/selection/calc_28',
                                name: 'electricCylinder28',
                                component: () => import('@/views/calculation-lecto/module-lecto/index.vue'),
                                meta: { title: "直线电机 - 工况选型" },
                            },
                            {
                                path: '/selection/calc_120',
                                name: 'rotatingPlatform',
                                component: () => import('@/views/calculation-lecto/rotating-platform/index.vue'),
                                meta: { title: "中空旋转平台 - 工况选型" },
                            },
                            {
                                path: '/selection/calc_3000000',
                                name: 'rotatingPlatform',
                                component: () => import('@/views/calculation-lecto/rotating-platform/index.vue'),
                                meta: { title: "转台 - 工况选型" },
                            },
                            {
                                path: '/selection/calc_121',
                                name: 'wokingscene121',
                                component: () => import('@/views/calculation-lecto/woking-scene/index.vue'),
                                meta: { title: "转盘工况计算" },
                            },
                            {
                                path: '/selection/rotating_121',
                                name: 'rotatingplatform121',
                                component: () => import('@/views/calculation-lecto/rotating-platform121/index.vue'),
                                meta: { title: "转盘工况计算" },
                            },
                        ]
                    },
                ]
            },
            {
                path: '/paramslecto/:cid',
                name: 'paramslecto',
                component: () => import('@/views/params-lecto/index.vue'),
                meta: { title: "参数选择" },
            },
            {
                path: '/productslecto/:cid',
                name: 'productslecto',
                component: () => import('@/views/products-lecto/index.vue'),
                meta: { title: "参数选择" },
            },
        ]
    }
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

export default router;
