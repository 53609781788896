<template>
    <div class="yq-content-view" :class="viewClass" :style="{ width: width, height: height }">
        <div class="yq-content-main" :class="bodyClass">
            <slot></slot>
        </div>
        <div class="yq-content-loading flex-center" v-show="loading">
            <div class="load-icon flex-center">
                <div class="setsvg">
                    <svg t="1688461401750" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12690" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M372.134088 1004.846981a509.94041 509.94041 0 0 1-159.298198-76.821527l2.164315-103.514738a93.088793 93.088793 0 0 0-74.238313-93.088794l-101.536602-20.991523A509.428422 509.428422 0 0 1 0.011636 537.983409l82.243949-62.834935a93.088793 93.088793 0 0 0 26.507034-116.104998l-46.89348-92.390627A514.152678 514.152678 0 0 1 172.225904 128.415991l100.419536 25.133974a93.088793 93.088793 0 0 0 107.284834-51.664281l43.007023-94.252403a515.246472 515.246472 0 0 1 88.434354-7.610009c30.137497 0 59.669917 2.606486 88.411081 7.610009l43.007023 94.252403a93.088793 93.088793 0 0 0 107.284834 51.664281l100.396264-25.133974a514.152678 514.152678 0 0 1 110.356765 138.236858l-46.870208 92.390627a93.088793 93.088793 0 0 0 26.483762 116.104998l82.243949 62.834935a509.428422 509.428422 0 0 1-39.213654 172.44699l-101.51333 20.968251a93.088793 93.088793 0 0 0-74.238312 93.088793l2.141042 103.538011a509.94041 509.94041 0 0 1-159.298198 76.798255l-79.684007-66.279221a93.088793 93.088793 0 0 0-119.060567 0l-79.684007 66.302493zM511.348378 721.461421a209.449785 209.449785 0 1 0 0-418.89957 209.449785 209.449785 0 0 0 0 418.89957z" :fill="theme.main" p-id="12691"></path></svg>
                    <div class="white-view"></div>
                </div>
                <div class="setsvg">
                    <svg t="1688461401750" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12690" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M372.134088 1004.846981a509.94041 509.94041 0 0 1-159.298198-76.821527l2.164315-103.514738a93.088793 93.088793 0 0 0-74.238313-93.088794l-101.536602-20.991523A509.428422 509.428422 0 0 1 0.011636 537.983409l82.243949-62.834935a93.088793 93.088793 0 0 0 26.507034-116.104998l-46.89348-92.390627A514.152678 514.152678 0 0 1 172.225904 128.415991l100.419536 25.133974a93.088793 93.088793 0 0 0 107.284834-51.664281l43.007023-94.252403a515.246472 515.246472 0 0 1 88.434354-7.610009c30.137497 0 59.669917 2.606486 88.411081 7.610009l43.007023 94.252403a93.088793 93.088793 0 0 0 107.284834 51.664281l100.396264-25.133974a514.152678 514.152678 0 0 1 110.356765 138.236858l-46.870208 92.390627a93.088793 93.088793 0 0 0 26.483762 116.104998l82.243949 62.834935a509.428422 509.428422 0 0 1-39.213654 172.44699l-101.51333 20.968251a93.088793 93.088793 0 0 0-74.238312 93.088793l2.141042 103.538011a509.94041 509.94041 0 0 1-159.298198 76.798255l-79.684007-66.279221a93.088793 93.088793 0 0 0-119.060567 0l-79.684007 66.302493zM511.348378 721.461421a209.449785 209.449785 0 1 0 0-418.89957 209.449785 209.449785 0 0 0 0 418.89957z" :fill="theme.main" p-id="12691"></path></svg>
                    <div class="white-view"></div>
                </div>
            </div>
            <span v-if="msg">{{msg}}</span>
        </div>
    </div>
</template>

<script>
    import theme from '@/mixins/theme'

    export default{
        props: {
            msg: { default: '' },
            width: { default: '100%' },
            height: { default: '100%' },
            viewClass: { default: '' },
            bodyClass: { default: '' },
            loading: { default: false }
        },
        mixins: [theme],
        data() {
            return {

            }
        }
    }
</script>

<style lang="less" scoped>
    .yq-content-view{
        height: 100%;
        width: 100%;
        position: relative;
    }
    .yq-content-main{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .yq-content-loading{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        background: rgba(6, 26, 35, 0.1);
        flex-direction: column;
        &>span{
            color: @main-color;
            font-size: 14px;
        }
    }
    .load-icon{
        width: 128px;
        height: 100px;
        .setsvg{
            position: relative;
        }
        .setsvg svg{
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
        }
        .white-view{
            width: 60%;
            height: 60%;
            border-radius: 100%;
            background-color: #ffffff;
            position: absolute;
            left: 20%;
            top: 20%;
            z-index: 1;
        }
        .setsvg:first-child{
            width: 50px;
            height: 50px;
            margin-top: 22px;
            -webkit-animation:changeright 4s linear infinite;
        }
        .setsvg:first-child+.setsvg{
            width: 32px;
            height: 32px;
            -webkit-animation:changeleft 4s linear infinite;
            margin-top: -22px;
        }
    }
</style>
