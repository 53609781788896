<template>
    <a-modal
      :visible="show"
      :footer="null"
      :width="460"
      @cancel="close"
      :zIndex="99999"
      :centered="true"
    >
        <div class="login-modal">
            <div class="title-view">{{ $t('login.title') }}</div>
            <div class="login-form">
                <div class="form-item">
                    <a-input v-model="value" :placeholder="$t('login.phonePlaceholder')" size="large" />
                </div>
                <div class="form-item">
                    <a-input v-model="code" :placeholder="$t('login.codePlaceholder')" size="large">
                        <a-button type="link" slot="suffix" :disabled="time != 60" @click="SendPhoneCode">{{time === 60 ? $t('login.codeGet') : `${time}s`}}</a-button>
                    </a-input>
                </div>
                <div class="check-choose">
                    <a-checkbox v-model="check">{{ $t('login.readAndAgree') }}</a-checkbox>
                    <span class="text-tip">{{ $t('login.userAgreement') }}</span>
                    <span>、</span>
                    <span class="text-tip">{{ $t('login.InformationProtection') }}</span>
                </div>
                <a-button type="primary" style="width: 100%;" size="large" @click="loginSubmit">{{ $t('login.button') }}</a-button>
            </div>
        </div>
    </a-modal>
</template>

<script>
import {message} from 'ant-design-vue'
import { mapActions } from 'vuex';
import {UserService,CompService} from '@/service'
import Event, {EventEnum} from '@/EventEmeitter'

let timeInterval = null;

export default {
    data() {
        return {
            show: false,
            value: '',
            code: '',
            time: 60,
            check: true,
        }
    },
    mounted() {
        Event.remove(EventEnum.LOGIN_OPEN, this.open);
        Event.on(EventEnum.LOGIN_OPEN, this.open);
    },
    methods: {
        ...mapActions({
            login: 'user/login',
            loginOnlyPhone: 'user/loginOnlyPhone',
        }),
        async loginSubmit() {
            if (!this.checkPhone()) {
                return false;
            }
            if (!this.code) {
                message.warning(this.$t('login.codeNoEmpty'));
                return false;
            }
            if (!this.check) {
                message.warning(this.$t('login.PleaseReadAndAgree'));
                return false;
            }
            const res = await this.login({phone: this.value, code: this.code})
            if (res) {
                Event.emit(EventEnum.LOGIN_SUCCESS);
                this.close();
            }
        },
        open() {
            this.value = '';
            this.code = '';
            this.time = 60;
            this.show = true;
        },
        close() {
            this.value = '';
            this.code = '';
            this.time = 60;
            this.show = false;
        },
        checkPhone() {
            if (!this.value) {
                message.warning(this.$t('login.phoneNoEmpty'));
                return false;
            }
            const regex = /^1[3456789]\d{9}$/;//使用此正则即可
            if (!regex.test(this.value)) {
                message.warning(this.$t('login.phoneNoRulr'));
                return false;
            }
            return true;
        },
        startInterval() {
            this.time = 59;
            timeInterval && clearInterval(timeInterval);
            timeInterval = setInterval(() => {
                let timeNum = this.time - 1;
                if (timeNum <= 0) {
                    timeNum = 60;
                    clearInterval(timeInterval);
                }
                this.time = timeNum;
            }, 1000)
        },
        async SendPhoneCode() {
            if (!this.checkPhone()) {
                return false;
            }
            if (!this.check) {
                message.warning(this.$t('login.PleaseReadAndAgree'));
                return false;
            }
            this.startInterval();

            const verifyRes = await CompService.userLoginIsVerify(this.value);
            if (verifyRes.errcode == 0) {
                if (verifyRes.data == false) {
                    await this.loginOnlyPhone(this.value);
                    Event.emit(EventEnum.LOGIN_SUCCESS);
                    this.close();
                    return;
                }
            }

            const res = await UserService.SendPhoneCode(this.value);
            if (res.code !== 0) {
                timeInterval && clearInterval(timeInterval);
                this.time = 60;
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .login-modal{
        padding: 16px 24px 24px 24px;
    }
    .title-view{
        opacity: 1;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #3D3D3D;
        margin-bottom: 32px;
    }
    .form-item{
        margin-bottom: 20px;
    }
    .check-choose{
        margin-bottom: 16px;
        // display: flex;
        // align-items: center;
        padding-top: 16px;
        span{
            margin-right: 4px;
        }
        .text-tip{
            cursor: pointer;
            color: @main-color;
        }
    }
</style>
