import Vue from 'vue'
import App from './App.vue'

import Antd, { message } from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.less';
import './design/index.less'

import i18n from './locale/index.js'



// 全局组件注册
import registerGlobComp from "./components/registerGlobComp";

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(VueViewer)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

import router from "./router";
import store from "./store";

Vue.config.productionTip = false

registerGlobComp(Vue);

message.config({
  top: `40vh`,
  maxCount: 1,
});

Vue.use(Antd);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
