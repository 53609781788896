import {ClassKeyData,GetClassDataByCid} from '@yqmodel/module'
import {AppConfig} from '@/libs/index.js';
import {DataService} from './service.js'

export class ProdClassService {
	/**
	 * 获取一级目录，产品选型的
	 */
	 static async GetSeriesRoots(){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetSeriesRoots`);
	}
	/**
	 * 获取一级目录，产品选型(PC)
	 * @returns 
	 */
	static async GetSeriesRootsPC() {
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetSeriesRootsPC`);
	}
	/**
	 * 选型方案
	 * @returns 
	 */
	static async GetSolution(){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetSolution`);
	}
	/**
	 * 获取分类列表，工况选型
	 */
	static async GetClasses(){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetClasses`);
	}
	
	/**
	 * 一级分类详情（系列（二级分类）和过滤器）
	 */
	static async GetSeriesRootDetails(rid){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetSeriesRootDetails?rid=${rid}&size=500`)
	}
	
	/**
	 * 根据系列ID获取过滤器（更多）
	 */
	static async GetFilterByClass(SID = '', page = 1, size = 15){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetFilterByClass?SID=${SID}&Page=${page}&Size=${size}`)
	}
	
	/**
	 * 获取过滤器详情
	 */
	static async GetFilterDetails(fid = '') {
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetFilterDetails?fid=${fid}`)
	}
	
	/**
	 * 通过选择参数获取过滤器详情(后退)
	 */
	static async GetFilterDetailsByParams(reducerFilters = [], cid, workingID){
		const ClassKey = ClassKeyData();
		let apiName = '';
		cid == 1 && (apiName = 'PlanetaryReducer');
		cid == 2 && (apiName = 'RSKFReducer');
		cid == 3 && (apiName = 'ScrewLift');
		cid == 4 && (apiName = 'Commutator');
		cid == 20 && (apiName = 'Module');
		cid == 30 && (apiName = 'BallScrew');
		cid == 31 && (apiName = 'BallRail');

		if (!apiName) {
			apiName = GetClassDataByCid(cid).class_module;
		}

		let data = reducerFilters;
		if (cid >= 10000) {
			apiName = 'ComLectotype';
			data = {
				cid: Number(cid),
				filters: reducerFilters,
			}
		}

		if (apiName == 'GearReducer') {
			data = {
				cid: Number(cid),
				filters: reducerFilters,
			}
			workingID && (data.workingID = workingID);
		}

		if ([
			ClassKey.ElectricCylinder.class_cid,
			ClassKey.ElectricCylinderTwo.class_cid,
			ClassKey.ElectricCylinderThree.class_cid,
			ClassKey.ElectricPushRod.class_cid,
			ClassKey.CommutatorPP.class_cid,
			ClassKey.GearReducerG.class_cid,
		].includes(Number(cid))) {
			data = {
				filters: reducerFilters,
			}
		}

		return await DataService.post(`${AppConfig.lectoHost}/api/${apiName}/GetFilterDetailsByParams`, {
			data
		});
	}
	
	/**
	 * 搜索
	 */
	static async SearchFilters(Page = 1, Size = 30, Keyword = ''){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/SearchFilters?Page=${Page}&Size=${Size}&Keyword=${Keyword}`)
	}
	
	
	
	
	
	
	
	
	
	
	/**
	 * 一级分类列表产品选型（一个子类4个产品，总数量不少于12个）
	 */
	static async GetProductsByRootClass(CID = ''){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetProductsByRootClass?CID=${CID}`)
	}
	
	/**
	 * 参数筛选
	 * @param {Object} CID
	 */
	static async GetClassLectotypeParams(CID){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdClass/GetClassLectotypeParams?cid=${CID}`)
	}
	
	/**
	 * 获取过滤产品的数量
	 * @param {Object} CID
	 * @param {Object} params
	 */
	static async FilterClassLectotypeParams(CID, params) {
		return await DataService.post(`${AppConfig.lectoHost}/api/ProdClass/FilterClassLectotypeParams?cid=${CID}`, { data: params })
	}
	
	/**
	 * 获取过滤产品
	 * @param {Object} CID
	 * @param {Object} params
	 */
	static async GetFilterProduct(CID, params, page = 1, size = 15) {
		return await DataService.post(`${AppConfig.lectoHost}/api/ProdClass/GetFilterProduct?cid=${CID}&page=${page}&size=${size}`, { data: params })
	}
}
