import axios from "axios";
import {isDesktop} from '@yqmodel/module'
import {message} from 'ant-design-vue'
import {StorageLib, UserEnum} from '@/libs'
import {UserService} from '@/service'

const Ajax = (method, url, data, option) => {
	
	const accessToken = UserService.getToken();
	
	// 默认的设置的请求头
	let token = accessToken || '';
	const ajaxHeader = {
		'Authorization': `Bearer ${token}` || '',
	};

	const optData = {
		url,
		method,
		data: data.data ? data.data : {},
		headers: data.headers ? Object.assign(ajaxHeader, data.headers) : ajaxHeader,
	};
	
	return new Promise((resolve, reject) => {
		axios({
			url: optData.url,
			data: optData.data,
			method: optData.method,
			headers: optData.headers,
		}).then((response) => {
			let errorMsg = '';
			if (response.data.errcode != undefined) {
				response.data.code = response.data.errcode;
			}
			if (response.status === 401) {
				errorMsg = '登录状态失效'
				StorageLib.remove(UserEnum.USER_INFO);
				window.location.href = '/';
			}else if(response.status !== 200){
				errorMsg = response.data.message || response.data.title || '服务器错误';
			}else if (response.data && response.data.code !== 0) {
				errorMsg = response.data.message || '服务器错误';
			}
			if (errorMsg && !option.noShowErr) {
				message.error(errorMsg);
			}
			
			resolve(response.data);
		}).catch((res) => {
			let err = '请求出错';
			if (res.response) {
				if (res.response.statusText) {
					err = res.response.statusText;
				}
				if (res.response.status == 401 && isDesktop) {
					err = res.response.data;
				}
			}

			err && message.error(err)
			resolve(res.data || res);
		})
	})
}

export class DataService {
	static async get(url = '', data = {}, option = {}) {
		return await Ajax('get', url, data, option)
	}
	static async post(url = '', data = {}, option = {}) {
		return await Ajax('post', url, data, option)
	}
};
