<template>
    <div class="layout-main-view">
        <header-ele :compDetail="compDetail"></header-ele>
        <div class="header-view-fixed"></div>
        <keep-alive :include="['selection', 'selectionParams']">
            <router-view></router-view>
        </keep-alive>

        <footer-ele></footer-ele>

        <login-modal></login-modal>

        <image-view></image-view>

        <yq-modalprogress></yq-modalprogress>

        <yq-record-generate></yq-record-generate>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {ipc} from '@yqmodel/module'

import {UtilLib,StorageLib} from '@/libs'

import HeaderEle from './header/index.vue'
import FooterEle from './footer.vue'
import LoginModal from './login-modal.vue'

import YqModalprogress from '@/components/yq-modalprogress/index.vue';
import ImageView from '@/components/image-view.vue';

import YqRecordGenerate from '@/components/yq-record-generate/yq-record-generate.vue';

export default{
    computed: {
        ...mapState({
            compDetail:state => state.comp.compDetail,
        }),
    },
    components: { HeaderEle, FooterEle, LoginModal, ImageView, YqModalprogress, YqRecordGenerate },
    created() {
        this.sessionInit();
    },
    mounted() {
        this.getCompDetail();
        this.getCompPctheme();
        this.getPcLayout();
        this.checkUserStatus();
        // console.log(this.$route)

        document.onkeydown=function(event) {
            // if (event.key == 'F5') {
            //     ipc.send('webReload')
            // }
            // if (event.key == 'F12') {
            //     ipc.send('openTool')
            // }
        }

        this.InitTask();
    },
    methods: {
        ...mapActions({
            getCompDetail: 'comp/getCompDetail',
            getCompPctheme: 'comp/getCompPctheme',
            getPcLayout: 'comp/getPcLayout',
            checkUserStatus: 'user/checkUserStatus',
            InitTask: 'recordGenerate/InitTask',
        }),
        // session数据初始化
        sessionInit() {
            const session = UtilLib.geturlparam(location.href).session;
            try {
                const sessionData = JSON.parse(decodeURIComponent(session));
                Object.keys(sessionData).forEach(item => {
                    sessionStorage.setItem(item, sessionData[item])
                })
                const resetUrl = UtilLib.delurlparam(location.href, 'session');
		        history.replaceState(null, '', resetUrl);
            } catch (error) {}
        }
    }
}
</script>

<style lang="less" scoped>
    .header-view-fixed{
        width: 100%;
        height: 48px;
    }
</style>