import {AppConfig} from '@/libs/index.js';
import {DataService} from './service.js'

export class RecordSelectionService {
	
	/**
	 * 确定选型
	 * @param {Object} prodId
	 * @param {Object} body
	 */
	static async SubmitSelection(prodId, body){
		return await DataService.post(`${AppConfig.lectoHost}/api/RecordSelection/SubmitSelection?prodID=${prodId}`, {
			data: body
		});
	}
	
	/**
	 * 删除选型
	 * @param {Object} id
	 */
	static async Delete(id){
		return await DataService.post(`${AppConfig.lectoHost}/api/RecordSelection/Delete?ID=${id}`);
	}
	
	/**
	 * 我的选型记录
	 */
	static async GetList(page = 1, size = 15){
		return await DataService.get(`${AppConfig.lectoHost}/api/RecordSelection/GetList?page=${page}&size=${size}`);
	}
	
	/**
	 * 选型记录详情
	 * @param {Object} id
	 */
	static async GetDetails(id){
		return await DataService.get(`${AppConfig.lectoHost}/api/RecordSelection/GetDetails?id=${id}`);
	}
	
}
