
import dayjs from 'dayjs';
import _ from 'lodash'
import { AppConfig } from './key' 
import {StorageLib} from './storage'

let timeValue = dayjs(dayjs().format('YYYY-MM-DD HH')).valueOf();

export class UtilLib {

	static removeStepUrl(step = 0){
		const key = location.href.includes('selection') ? 'params' : 'calc';
		const urlObj = StorageLib.sessionGet('selection_step_url') || {};
		Object.keys(urlObj).forEach(item => {
			const [type, num] = item.split('_');
			console.log(type, num);
			if (type != key || Number(num) > step) {
				delete urlObj[item]
			}
		})
		StorageLib.sessionSet('selection_step_url', urlObj);
	}

    /**
     * node后端服务的完整图片地址
     * @param {*} url url
     * @param {*} v str
     * @returns 
     */
    static nodeImgUrl(url) {
        if (!url) {
            return '';
        }
		if (url.startsWith('/offline')) {
            return `${url}?v=${timeValue}`;
        }
        return `${AppConfig.nodeHost}/public${url}?v=${timeValue}`
    }

	static netImgUrl(url) {
        if (!url) {
            return '';
        }
        return `${AppConfig.lectoHost}/images${url}?v=${timeValue}`
    }
	
	static geturlparam(url){
		!url && (url = location.href);
		// 创建空对象存储参数
		let obj = {};
		try {
			// 通过 ? 分割获取后面的参数字符串
			let urlStr = url.split('?')[1];
			// 再通过 & 将每一个参数单独分割出来
			let paramsArr = urlStr.split('&');
			for(let i = 0,len = paramsArr.length;i < len;i++){
					// 再通过 = 将每一个参数分割为 key:value 的形式
					let arr = paramsArr[i].split('=');
					obj[arr[0]] = arr[1];
			}
		} catch (error) {}
		return obj;
	}

	static delurlparam(url,parameter) {
		var urlparts = url.split('?');
		if(urlparts.length >= 2) {
			//参数名前缀
			var prefix = encodeURIComponent(parameter) + '=';
			var pars = urlparts[1].split(/[&;]/g);

			//循环查找匹配参数
			for(var i = pars.length; i-- > 0;) {
				if(pars[i].lastIndexOf(prefix, 0) !== -1) {
					//存在则删除
					pars.splice(i, 1);
				}
			}

			return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
		}
		return url;
	}
	
	static strSize(str) {
		let total = 0;
		for (let i = 0; i < str.length; i++) {
			let charCode = str.charCodeAt(i);
			if (charCode <= 0x007f) {
				total += 1;
			} else if (charCode <= 0x07ff) {
				total += 2;
			} else if (charCode <= 0xffff) {
				total += 3;
			} else {
				total += 4;
			}
		}
		return total;
	}
	
	static byteLength(str) {
		var b = 0, l = str.length;  //初始化字节数递加变量并获取字符串参数的字符个数
		if(l) {  //如果存在字符串，则执行计划
			for(var i = 0; i < l; i ++) {  //遍历字符串，枚举每个字符
				if(str.charCodeAt(i) > 255) {  //字符编码大于255，说明是双字节字符
					b += 2;  //则累加2个
				}else {
					b ++;  //否则递加一次
				}
			}
			return b;  //返回字节数
		} else {
			return 0;  //如果参数为空，则返回0个
		}
	}

	/**
	 * 识别input参数的输入规则
	 * @param {Object} params
	 */
	static paramsInputRule(params) {
		const values = params.values || [];
		if (values.length <= 0) {
			return { text: params.displayName };
		}
		const minArr = [];
		const maxArr = [];
		let step = 0;
		values.forEach(item => {
			const data = item.replace('{', '').replace('}', '').replace('~', ':');
			const dataArr = data.split(':');
			minArr.push(dataArr[0]);
			maxArr.push(dataArr[1]);
			!step && (step = dataArr[2])
		})
		const min = Math.min(...minArr);
		const max = Math.max(...maxArr);
		return {
			min,
			max,
			step
		};
	}

	/**
	 * 根据classname查找元素是否包含在指定元素内
	 * @param childClass 
	 * @param parentClass 
	 * @returns 
	*/
	static matchesSelector(childClass, parentClass){
		if (childClass == parentClass) return true;
		if (!childClass) return false;
		const element = document.querySelector(`.${childClass}`);
		const selector = `.${parentClass} *`;
		if(element.matches){
		  return element.matches(selector);
		} else if(element.matchesSelector){
		  return element.matchesSelector(selector);
		} else if(element.webkitMatchesSelector){
		  return element.webkitMatchesSelector(selector);
		} else if(element.msMatchesSelector){
		  return element.msMatchesSelector(selector);
		} else if(element.mozMatchesSelector){
		  return element.mozMatchesSelector(selector);
		} else if(element.oMatchesSelector){
		  return element.oMatchesSelector(selector);
		}
	}

	/**
     * 开启全屏
     */
    static launchFullscreen() {
		const element = (window).document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen()
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullScreen()
        }
    }

    /**关闭全屏 */
    static exitFullscreen() {
        const document = (window).document;
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        }
    }

	/**
	 * 检测是否全屏
	 * @returns 
	 */
	static checkFull() {
		var isFull =
			document.fullscreenElement ||
			document.mozFullScreenElement ||
			document.webkitFullscreenElement
		if (isFull === undefined) isFull = false
		return isFull
	}

	/**
	 * 16进制转rgba
	 * @param {*} hex 
	 * @returns 
	 */
	static hexToRgba(hex, opacity = 1) {
		if (!hex) {
			return { rgba: `rgba(255,255,255,${opacity})`, r: 255, g: 255, b: 255, a : opacity }
		}
		const rgba = [];
		hex = hex.replace('#', '').padEnd(8, 'F');
		for (let i = 0; i < hex.length; i+=2) {
			rgba.push(parseInt(hex.slice(i, i+2), 16))
		}
		const [r,g,b] = rgba;
		return { rgba: `rgba(${r},${g},${b},${opacity})`, r, g, b, a: opacity, hex: this.colorutils(`rgb(${r},${g},${b})`, opacity * 100) };
	}
	

	/**
	 * hsl模式：分为了3个部分，色值（360度圆形的方式呈现），饱和度（100），明度（100），我们就是改变明度
	 * 思路，将rgb转成hsl模式，通过hsl模式将颜色变浅，然后在转成rgb方式
	 * color 传递的颜色，支持rgb与十六进制（可以不带#）的字符串，rgba将自动转成rgb，如果想在rgba的基础上变色，请调两次函数
	 * opacity 阈值：0-100 -》 0 最暗（黑色） 100 最亮（白色）
	 * 返回值：如果是rgb则返回处理后的rgb，如果是十六进制则返回处理后的十六进制
	 */
	static colorutils(color, opacity) {
		// 将rgb或者十六进制颜色统一转成hsl模式
		function allhsl(color){
			// 首先对颜色判断，如果是rgb则走rgb模式，因为#号是特殊字符，网址传参都有限制，所以不判断#
			if(color.indexOf('rgb') != -1){
				var start = color.indexOf('(')+1;
				var startval = color.substr(color.indexOf('(')+1)
				var end = startval.indexOf(')');
				var val = startval.substr(0, end)
				var colorarr = val.split(",");
				return rgbtohsl(colorarr[0],colorarr[1],colorarr[2])
			} else {
				return colorToRgb(color);
			}
		}
		// 然后就是转化成处理后的颜色 如果传rgb就返rgb模式，如果是十六进制就返十六进制
		function resultRgb(color, opacity){
			var arr = allhsl(color);
			var result = hsltorgb(arr[0], arr[1], opacity);
			if(color.indexOf('rgb') != -1){
				return "rgb("+result[0]+","+result[1]+","+result[2]+")";
			} else {
				return colorToHex(result);
			}
		}
		// 将十六进制转成rgb
		function colorToRgb(color){
			if(color.indexOf("#") != -1){
				color = color.replace("#", "", color)
			}
			var color1 = parseInt(color.substr(0, 2), 16);
			var color2 = parseInt(color.substr(2, 2), 16);
			var color3 = parseInt(color.substr(4, 2), 16);
			var str = "rgb("+color1+","+color2+","+color3+")";
			// console.log(str)
			return rgbtohsl(color1, color2, color3);
		}
		// 将rgb转成hsl模式
		function rgbtohsl(r,g,b){
			r=_.divide(r, 255);
			g=_.divide(g, 255);
			b=_.divide(b, 255);
		
			var min=Math.min(r,g,b);
			var max=Math.max(r,g,b);
			var l= _.divide(_.add(min,max), 2);
			var difference = _.subtract(max, min);
			var h,s,l;
			if(max==min){
				h=0;
				s=0;
			}else{
				s=l>0.5? _.divide(difference, _.subtract(_.subtract(2.0, max), min)) : _.divide(difference, _.add(max,min));
				switch(max){
					case r: h= _.add(_.divide(_.subtract(g, b), difference), (g < b ? 6 : 0));break;
					case g: h=_.add(2.0, _.divide(_.subtract(b, r), difference));break;
					case b: h=_.add(4.0, _.divide(_.subtract(r, g), difference));break;
				}
				h=Math.round(_.multiply(h, 60));
			}
			s=Math.round(_.multiply(s, 100));//转换成百分比的形式
			l=Math.round(_.multiply(l, 100));
			return [h,s,l];
		}
		// 将hsl模式转成rgb
		function hsltorgb(h,s,l){
			var h=_.divide(h, 360);
			var s=_.divide(s, 100);
			var l=_.divide(l, 100);
			var rgb=[];
		
			if(s==0){
				rgb=[Math.round(_.multiply(l, 255)),Math.round(_.multiply(l, 255)),Math.round(_.multiply(l, 255))];
			}else{
				var q=l>=0.5?_.subtract(_.add(l, s), _.multiply(l, s)):_.multiply(l, _.add(1, s));
				var p=_.subtract(_.multiply(2, l), q);
				var tr=rgb[0]=_.add(h, _.divide(1, 3));
				var tg=rgb[1]=h;
				var tb=rgb[2]=_.subtract(h, _.divide(1, 3));
				for(var i=0; i<rgb.length;i++){
					var tc=rgb[i];
					if(tc<0){
						tc=_.add(tc, 1);
					}else if(tc>1){
						tc=_.subtract(tc, 1);
					}
					switch(true){
						case (tc<_.divide(1, 6)):
							tc=_.add(p, _.multiply(_.multiply(_.subtract(q, p), 6), tc));
							break;
						case (_.divide(1, 6)<=tc && tc<0.5):
							tc=q;
							break;
						case (0.5<=tc && tc<_.divide(2, 3)):
							tc=_.add(p, _.multiply(_.subtract(q, p), _.subtract(4, _.multiply(6, tc))));
							break;
						default:
							tc=p;
							break;
					}
					rgb[i]=Math.round(_.multiply(tc, 255));
				}
			}
			
			return rgb;
		}
		// rgb转十六进制 color 为[r,b,g]的格式 
		function colorToHex(color){
			function componentToHex(c) {
			  var hex = c.toString(16);
			  return hex.length == 1 ? "0" + hex : hex;
			}
			return "#" + componentToHex(color[0]) + componentToHex(color[1]) + componentToHex(color[2]);
		}
		return resultRgb(color, opacity)
	}

	/**
	 * js将一句英文截取为两段
	 * @param {*} sentence 
	 * @returns 
	 */
	static splitSentence(sentence) {
		const words = sentence.split(' ');
		const midpoint = Math.ceil(words.length / 2);
		
		const firstHalf = words.slice(0, midpoint).join(' ');
		const secondHalf = words.slice(midpoint).join(' ');
		
		return [firstHalf, secondHalf];
	}

	/**
	 * 判断字符中是否包含汉字
	 * @param {*} input 
	 * @returns 
	 */
	static hasChinese(input) {
		const pattern = /[\u4e00-\u9fa5]/; // 匹配汉字的范围
  		return pattern.test(input);
	}
	
}
