<template>
	<yq-modal
        :title="title"
        :status="show"
        :width="width + 'px'"
		@close="show = false"
	>
		<div class="yq-module-sheet">
            <div class="desc-view" v-if="desc">{{desc}}</div>
			<div class="actions-img-view" v-if="type == 'image'">
                <div @click="buttonClick(item)" class="sku-lists" :class="item.value === value ? 'active' : ''" v-for="(item, index) in actions" :key="index">
					<img :src="getPath(item.icon)" mode="" />
					<div class="text-view">{{item.value}}</div>
				</div>
				<div class="sku-lists-empty" v-for="(item, index) in new Array(6).fill('')" :key="index"></div>
            </div>
            <div class="actions-view" v-if="type == 'default'">
                <div
                    class="list-li span-clamp1"
                    :style="{ backgroundColor: '#F2F2F6', borderColor: '#F2F2F6' }"
                    v-for="(item, index) in actions"
                    :key="index"
                    @click="buttonClick(item)"
                >{{item.name || item.value}}</div>
            </div>
        </div>
	</yq-modal>
</template>

<script>
	import {UtilLib} from '@/libs'
	export default{
		props: {
			type: { default: 'default' }
		},
		data(){
			return {
				width: 0,
				title: '',
				desc: '',
				value: '',
				actions: [],
				show: false,
				otherOption: {},
			}
		},
		mounted() {
			
		},
		methods: {
			getPath(url) {
				return UtilLib.netImgUrl(url);
			},
            buttonClick(value) {
                this.close();
				this.$emit('actionClick', value, this.otherOption);
			},
			open(data = {}) {
				this.title = data.title || '';
				this.desc = data.desc || '';
				this.value = data.value || '';
				this.actions = data.actions || [];
				this.otherOption = data.otherOption || {};

				let width = 50 + (this.actions.length * 200);
				if (width >= 850) {
					width = 850;
				}
				this.width = width;

				this.show = true;
			},
			close() {
				this.show = false;
				this.$emit('close');
			}
		}
	}
</script>

<style scoped lang="less">
	.desc-view{
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }
    .actions-view{
        display: flex;
        flex-wrap: wrap;
        padding: 20px 30px;
		max-height: 500px;
    	overflow-y: auto;
    }
    .list-li{
		height: 36px;
		display: flex;
		padding: 0 12px;
		line-height: 36px;
		border: 1px solid #ffffff;
		font-size: 18px;
		margin-right: 24px;
		margin-bottom: 15px;
		justify-content: center;
		cursor: pointer;
	}

	.actions-img-view{
        display: flex;
        flex-wrap: wrap;
		justify-content: space-between;
        padding: 30px 30px;
		max-height: 500px;
    	overflow-y: auto;
    }
	.sku-lists{
		width: 180px;
		height: 180px;
		margin-bottom: 50px;
		position: relative;
		border: 1px solid @border-color;
		img{
			width: 100%;
			height: 100%;
		}
		.text-view{
			width: 100%;
			height: 50px;
			padding: 0 8px;
			position: absolute;
			left: 0;
			bottom: -50px;
			justify-content: center;
			display: flex;
			align-items: center;
			text-align: center;
		}
	}
	.sku-lists.active{
		border: 1px solid @main-color;
		.text-view{
			color: @main-color;
		}
	}
	.sku-lists-empty{
		width: 180px;
		height: 1px;
	}
</style>
