import {AppConfig, StorageLib, UserEnum} from '@/libs/index.js';
import {DataService} from './service.js'

export class UserService {

	//获取当前用户数据
	static user() {
		return StorageLib.get(UserEnum.USER_INFO) || {};
	}
	static getToken() {
		const accessToken = this.user().accessToken || {};
		if (!accessToken.token) {
			return '';
		}
		if (new Date(accessToken.expires).getTime() <= new Date().getTime()) {
			return '';
		}
		return accessToken.token;
	}

	/**
	 * 设置用户信息
	 * @param userInfo
	 * @returns
	 */
	 static setUserInfo(userInfo = {}) {
		return StorageLib.set(UserEnum.USER_INFO, userInfo);
	}
	static clearUserInfo() {
		return StorageLib.remove(UserEnum.USER_INFO);
	}
	
	static async login(phoneNumber, code){
		return await DataService.post(`${AppConfig.lectoHost}/api/User/LoginPC`, { data: { phoneNumber, code, appID: 'pc' } });
	}

	static async LoginOnlyPhone(phoneNumber){
		return await DataService.post(`${AppConfig.lectoHost}/api/User/Login`, { data: { phoneNumber, appID: 'pc' } });
	}

	static async SendPhoneCode(phoneNumber, type = 0){
		return await DataService.get(`${AppConfig.lectoHost}/api/Sms/SendPhoneCode?phoneNumber=${phoneNumber}&type=${type}`);
	}
	
	static async update(email) {
		return await DataService.post(`${AppConfig.lectoHost}/api/User/Update`, { data: { email } });
	}
	
	/**
	 * 我的记录统计
	 */
	static async MyRecordCount(){
		return await DataService.get(`${AppConfig.lectoHost}/api/User/MyRecordCount`);
	}
	
}
