
export class StorageLib {
	
	/**
	 * 本地缓存中设置值
	 * @param {Object} key
	 * @param {Object} data
	 */
	static set(key, data){
		localStorage.setItem(key, JSON.stringify(data));
	}
	
	/**
	 * 从缓存中获取指定key的数据
	 * @param {Object} key
	 */
	static get(key){
		let storageData = null;
		try {
			storageData = JSON.parse(localStorage.getItem(key) || '');
		} catch (e) { }
		return storageData;
	}
	
	/**
	 * 从本地缓存中异步移除指定 key
	 * @param {Object} key
	 */
	static remove(key){
		localStorage.removeItem(key);
	}
	
	/**
	 * 从本地缓存中异步获取指定 key 对应的内容（获取后会删除掉）
	 * @param {Object} key
	 */
	static getOnece(key){
		const data = this.get(key);
		this.remove(key);
		return data;
	}

	/**
	 * 会话缓存中设置值
	 * @param {Object} key
	 * @param {Object} data
	 */
	static sessionSet(key, data){
		sessionStorage.setItem(key, JSON.stringify(data));
	}
	
	/**
	 * 从会话缓存中获取指定key的数据
	 * @param {Object} key
	 */
	static sessionGet(key){
		let storageData = null;
		try {
			storageData = JSON.parse(sessionStorage.getItem(key) || '');
		} catch (e) { }
		return storageData;
	}
	
	/**
	 * 从会话缓存中异步移除指定 key
	 * @param {Object} key
	 */
	static sessionRemove(key){
		sessionStorage.removeItem(key);
	}
	
}
