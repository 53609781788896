import Vue from "vue";
import Vuex from "vuex";

import { CompStore } from "./modules/comp";
import { UserStore } from "./modules/user";
import RecordGenerateModule from './modules/record-generate.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: UserStore,
    comp: CompStore,
    recordGenerate: RecordGenerateModule,
  },
});
