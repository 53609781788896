<template>
	<div class="generate-backview" v-if="taskList.length > 0">
		<div class="task-list" :style="{ maxHeight: '70vh' }">
            <div class="task-item flex align-items-center" v-for="(item, index) in taskList" :key="index">
                <div class="status-icon flex align-items-center just-content-center">
                    <div class="loading-status flex-center" v-if="item.status == 'loading'">
                        <div class="max-set"><a-icon type="setting" :style="{ color: '#3B54FB', fontSize: '22px' }" /></div>
					    <div class="mini-set"><a-icon type="setting" :style="{ color: '#3B54FB', fontSize: '16px' }" /></div>
                    </div>
                    <img :src="`${staticHost}/icon/status-wait.png`" mode="" v-if="item.status == 'wait'" />
                    <img :src="`${staticHost}/icon/status-success.png`" mode="" v-if="item.status == 'success'" />
                    <img :src="`${staticHost}/icon/status-error.png`" mode="" v-if="item.status == 'error'" />
                    <img :src="`${staticHost}/icon/status-error.png`" mode="" v-if="item.status == 'timeout'" />
                </div>
                <div class="status-text text-clamp1">
                    {{ statusObj[item.status] }}
                </div>
                <div class="sku-name text-clamp1">
                    {{ item.skuName }}
                </div>
                <div class="task-title text-clamp1">
                    {{ item.title }}
                </div>
                <div class="task-button" hover-class="view-hover-c" v-if="['error', 'timeout', 'success'].includes(item.status)">

                    <a-button type="danger" shape="round" v-if="['error', 'timeout'].includes(item.status)" @click="taskRetry(item.taskid)">{{ $t('button.retry') }}</a-button>

                    <a-button type="primary" shape="round" v-if="['success'].includes(item.status)" @click="lookDetail(item)">{{ $t('button.view') }}</a-button>
                </div>
            </div>
        </div>
	
		<!-- <yq-filesave ref="yqFilesaveRef" @success="downSuccess"></yq-filesave> -->
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
    import {message} from 'ant-design-vue'
	import i18n from '@/locale/index.js'
	import { RecordDownloadService } from '@/service/index.js'
	import {GenerateStatus,AppConfig,GenerateEvent,FsLibs} from '@/libs/index.js'
    import Event, {EventEnum} from '@/EventEmeitter'
	
	export default {
		data() {
			return {
				staticHost: AppConfig.staticHost,
				
				scrollTop: 0,
				old: {
					scrollTop: 0,
				},
			}
		},
		computed: {
			...mapState({
				taskList: state => state.recordGenerate.taskList.filter(item => item.downloadType != 4),
			}),
			statusObj() {
				return {
					[GenerateStatus.WAIT]: this.$t('system.wait'),
					[GenerateStatus.LOADING]: this.$t('file.generate'),
					[GenerateStatus.SUCCESS]: this.$t('file.generateSuccess'),
					[GenerateStatus.ERROR]: this.$t('system.error'),
					[GenerateStatus.TIMEOUT]: this.$t('system.timeout'),
				}
			},
		},
		mounted() {
			Event.remove(GenerateEvent.CHANGE, this.autoSetScroll);
			Event.on(GenerateEvent.CHANGE, this.autoSetScroll);
			this.autoSetScroll();
		},
		methods: {
			...mapActions({
				taskRetry: 'recordGenerate/taskRetry',
				taskRemove: 'recordGenerate/taskRemove',
			}),
			autoSetScroll() {
				const findFirstLoading = this.taskList.findIndex((item) => item.status == GenerateStatus.LOADING);
				const isLast = findFirstLoading >= (this.taskList.length - 2)
				if (findFirstLoading > 0) {
                    document.querySelector('.generate-backview').scrollTop = 52 * findFirstLoading - (isLast ? 0 : 28)
				}
			},
			lookDetail(task) {
				if (task.status != GenerateStatus.SUCCESS) {
					return false;
				}
				if (task.downloadType == 4) {
					// this.lookModal(task);
				} else {
					this.fileDownLoad(task);
				}
			},
			lookModal(task) {
				const {skuName, selectionId, downloadType, orderParams, previewType, previewUrl} = task;
				const downParams = JSON.stringify({
					selectionId,
					downloadType: 2,
					orderParams,
				});
				let src = '';
				let skuNameTxt = FsLibs.fileNameReplace(skuName);
				const stlpath = `/${AppConfig.compKey}_api${previewUrl}`;
				if (previewType == 'stl') {
					src = encodeURIComponent(`https://www.yunqi3d.com/mobile/#/stlpreview?stlpath=${encodeURIComponent(stlpath)}&title=${encodeURIComponent(skuNameTxt)}&downParams=${encodeURIComponent(downParams)}&locale=${i18n.locale}`)
				}
				if (previewType == 'brep') {
					src = encodeURIComponent(`https://www.yunqi3d.com/mobile/#/breppreview?brep=${encodeURIComponent(stlpath)}&title=${encodeURIComponent(skuNameTxt)}&downParams=${encodeURIComponent(downParams)}&source=wxa&locale=${i18n.locale}`)
				}
				uni.navigateTo({
					url:`/secondPages/pages/page-webview/page-webview?src=${src}`,
				})
				this.downSuccess({}, task);
			},
			async fileDownLoad(task) {
				const {selectionId,downloadType,orderParams,insert3d,skuName,button,contentLength,extension} = task;
				const downData = await RecordDownloadService.SendDownload(
					selectionId,
					downloadType,
					Object.values(orderParams || {}).map(item => ({ paramName: item.paramName, value: item.value })),
					'',
					insert3d
				);
				if (downData.code != 0) {
                    message.error(this.$t('file.generateError'));
					return false;
				}
				const downPath = downData.data.downloadServerPath;
				if (downPath === null) {
					message.error(this.$t('file.generateError'));
					return;
				}
                Event.emit(EventEnum.PROGRESS_OPEN,{
                    title: this.$t('file.downLoading') + '...',
                    halfTime: 2000,
                })
				let fileType = 'pdf';
				downloadType === 2 && (fileType = 'step');
				downloadType === 3 && (fileType = 'zip');
				downloadType === 5 && (fileType = 'dxf');
				downloadType === 7 && (fileType = 'xlsx');
				
				extension && (fileType = extension.replace('.',''));
				
				const fileUrl = RecordDownloadService.getPath(downPath);
                FsLibs.saveFile(
                    fileUrl,
                    downloadType === 3 ? `${skuName}.${fileType}` : `${skuName}_${button.label}.${fileType}`,
                );
				this.taskRemove(task.taskid);
			},
			downSuccess(data, task) {
				this.taskRemove(task.taskid);
			},
		}
	}
</script>

<style scoped lang="less">
	.generate-backview{
		pointer-events: all;
		padding: 10px 20px;
		background-color: #ffffff;
        max-height: 60vh;
        overflow-y: auto;
		
		.task-item{
			height: 52px;
			line-height: 52px;
			border-bottom: 1px solid @bg-color;
			font-size: 16px;
			color: @text-color;
			.status-icon{
				width: 32px;
				height: 100%;
				margin-right: 6px;
				img{
					width: 23px;
					height: 23px;
				}
			}
			.loading-status{
				position: relative;
				left: -5px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 43px;
			}
			.mini-set{
				position: relative;
				left: -3px;
			}
			.status-text{
				width: 76px;
				font-size: 16px;
				color: @main-color;
			}
			.sku-name{
				width: 0;
				flex: 1;
			}
			.task-title{
				width: 70px;
				margin-left: 12px;
			}
			.task-button{
				width: 80px;
			}
		}
		.max-set{
			-webkit-animation:changeright 4s linear infinite;
		}
		.mini-set{
			-webkit-animation:changeleft 4s linear infinite;
		}
	}
	
	@keyframes changeright{
	        
	    0%{-webkit-transform:rotate(0deg);}
	
	    50%{-webkit-transform:rotate(180deg);}
	
	    100%{-webkit-transform:rotate(360deg);}
	}
	@keyframes changeleft{       
	    
	    0%{-webkit-transform:rotate(0deg);}
	
	    50%{-webkit-transform:rotate(-180deg);}
	
	    100%{-webkit-transform:rotate(-360deg);}
	}
</style>