import { ElectronLib } from './electron'

const desktopInfo = ElectronLib.desktopInfo();

const mainHost = 'https://www.yunqi3d.com';

let compKey = 'yqdev';
// let compKey = 'dechi';
const hosts = location.hostname.split('.');
if (hosts[1] === 'comp') {
	compKey = `${hosts[0]}`
}

// appConfig
export const AppConfig = {
	compKey,
	siteKey: compKey,
	mainHost,
	lectoHost: ElectronLib.check() ? 'http://localhost:' + desktopInfo.servicePort : `/lecto_api/${compKey}_api`,
	nodeHost: `${mainHost}/webApi`,
	deskTopHost: `/offline/request`,
	staticHost: ElectronLib.check() ? '/offline/cosstatic/xcx' : 'https://static-1311029656.cos.ap-nanjing.myqcloud.com/xcx',
	bomCos: 'https://bom-1311029656.cos.ap-nanjing.myqcloud.com',
	beian: compKey == 'atlas' ? false : true,
};

export const StorageKey = {
	PRO_SKU_PARAMS: 'pro_sku_selection_data',
	/**参数确定页面的默认相关参数 */
	PARAMS_LECTO_DATA: 'selection_params_lecto',
	/**产品详情页的选型参数 */
	PRODUCT_DETAIL_PARAMS: 'product_detail_params',

	/**工况选型页面的默认相关参数 */
	CALCULATION_DATA: 'calculation_data',

	/**文件生成的后台进程数据 */
	GENERATE_DATA: 'storage_generate_data',

	/**工况场景页面计算结果的数据 */
	WORKING_SCENE_CALC_DATA: 'working_scene_calc_data',
}

export const UserEnum = {
	USER_INFO: compKey + '_sel_comp_pc_user',
	COMP_INFO: compKey + '_sel_comp_pc_info',
};



/**任务状态 */
export const GenerateStatus = {
	/**等待中 */
	WAIT: 'wait',
	/**生成中 */
	LOADING: 'loading',
	/**生成成功 */
	SUCCESS: 'success',
	/**生成失败 */
	ERROR: 'error',
	/**生成超时 */
	TIMEOUT: 'timeout',
};
/**生成任务事件通知 */
export const GenerateEvent = {
	/**任务状态变化 */
	CHANGE: 'generate_change',
	TASK_REMOVE: 'generate_task_move',
	PROGRESS_OPEN: 'generate_progress_open',
	PROGRESS_CLOSE: 'generate_progress_close',
};
/**生成任务窗口状态 */
export const GeneratePopup = {
	/**前台 */
	FRONTEND: 'frontend',
	/**后台 */
	BACKGROUNP: 'background',
}
  


