import {isDesktop} from '@yqmodel/module'
import {AppConfig,StorageLib,UserEnum} from '@/libs/index.js';
import {DataService} from './service.js'

export class CompService {
	
	/**
	 * 选型样本列表
	 */
	static async DocumentGetList(page = 1, size = 15){
		return await DataService.get(`${AppConfig.lectoHost}/api/CompDocument/GetList?Page=${page}&Size=${size}`);
	}

	static getCompConfigByLocal() {
		return StorageLib.get(UserEnum.COMP_INFO);
	}
	
	static async getCompConfig() {
		return await DataService.get(`${AppConfig.nodeHost}/share/comp_config?comp_key=${AppConfig.compKey}`);
	}

	static async getLayoutData(type) {
		return await DataService.get(`${AppConfig.nodeHost}/share/comp/layoutData?comp_key=${AppConfig.compKey}&type=${type}`);
	}

	static async getLocaleData() {
		if (isDesktop) {
			return await DataService.get(`${AppConfig.deskTopHost}/comp_locale.json`);
		}
		return await this.layoutInfo('pclocale');
	}

	static async getCompDetail() {
		let url = `${AppConfig.nodeHost}/share/comp_detail?comp_key=${AppConfig.compKey}`;
		if (isDesktop) {
			url = `${AppConfig.deskTopHost}/comp_detail.json`;
		}
		return await DataService.get(url);
	}

	/**客户端获取首页显示文件 */
	static async getPcHomeImg() {
		let res = null;
		if (isDesktop) {
			res = await DataService.get(`${AppConfig.deskTopHost}/home_rl_img.json`);
		} else {
			const leftImgKey = 'pc_home_left_img';
			const leftImgKeyBg = 'pc_home_left_imgbg';
			const rightImgKey = 'pc_home_right_img';
			const rightImgKeyBg = 'pc_home_right_imgbg';
			res = await this.getCompFile(`${leftImgKey},${rightImgKey},${leftImgKeyBg},${rightImgKeyBg}`);
		}
		return res;
	}

	static async getCompPclayout() {
		if (isDesktop) {
			return await DataService.get(`${AppConfig.deskTopHost}/comp_pclayout.json`);
		}
		return await DataService.get(`${AppConfig.nodeHost}/share/comp_pclayout?comp_key=${AppConfig.compKey}`);
	}

	static async getCompFile(file_type) {
		return await DataService.get(`${AppConfig.nodeHost}/share/comp_file?comp_key=${AppConfig.compKey}&file_type=${file_type}`);
	}
	
	static async getCompData(data_type = '') {
		const comp_info = StorageLib.get(UserEnum.COMP_INFO);
		return await DataService.get(`${AppConfig.nodeHost}/share/comp_data?appid=${comp_info.appid}&data_type=${data_type}`);
	}

	/**获取应用主题配置 */
	static async getCompPctheme() {
		let url = `${AppConfig.nodeHost}/share/comp_pctheme?comp_key=${AppConfig.compKey}`;
		if (isDesktop) {
			url = `${AppConfig.deskTopHost}/comp_pc_theme.json`;
		}
		let res = await DataService.get(url);
		let themeData = {
            main: '#0D475F', // 主色
            bgDark: '#D6D8DA', // 深色背景
            handle: '#0D475F', // 可操作部位颜色
            handleEmphasis: '#0D475F', // 可操作部位重点颜色
            emphasis: '#D40A25', // 重点突出显示颜色
            homeLeft: '#0181FE',
            homeRight: '#F9B03D',
        };
		if (res.errcode === 0) {
			Object.keys(res.data).forEach(item => {
				themeData[item] = res.data[item];
			})
		}
		return themeData;
	}

	/**
	 * 返回的直接是反序列化后的数据
	 * @param {*} type 
	 * @returns 
	 */
	static async layoutInfo(type) {
		if (!type) return null;
		if (isDesktop) {
			return await DataService.get(`${AppConfig.deskTopHost}/layoutInfo/${type}.json`);
		}
		return await DataService.get(`${AppConfig.nodeHost}/share/comp/layoutInfo?comp_key=${AppConfig.compKey}&type=${type}`);
	}
	
	// =====================
	// =======新闻列表======
	// =====================
	static async newLists(page = 1, size = 15) {
		return await DataService.get(`${AppConfig.lectoHost}/api/CompNews/GetList?Page=${page}&Size=${size}`);
	}
	static async newTop() {
		return await DataService.post(`${AppConfig.lectoHost}/api/CompNews/GetTop`);
	}


	//#region CompUser 企业用户相关
	static async userLoginIsVerify(phone) {
		return await DataService.get(`${AppConfig.nodeHost}/share/comp/userLoginIsVerify?comp_key=${AppConfig.compKey}&phone=${phone}`);
	}
	//#endregion
	
}
