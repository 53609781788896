import dayjs from 'dayjs';
import {AppConfig} from '@/libs/index.js';
import {DataService} from './service.js'

let timeValue = dayjs(dayjs().format('YYYY-MM-DD HH')).valueOf();

export class ProductService {
	
	static prodPath(url){
		return `${AppConfig.lectoHost}/images${url}?v=${timeValue}`
	}
	
	
	
	/**
	 * 获取产品选型参数
	 * @param {Object} prodId
	 */
	static async GetLectotypeParams(prodId){
		return await DataService.get(`${AppConfig.lectoHost}/api/Prod/GetLectotypeParams?prodId=${prodId}`);
	}
	/**
	 * 生成选型参数
	 */
	static async FilterLectotypeParams(prodId, data = []) {
		return await DataService.post(`${AppConfig.lectoHost}/api/Prod/FilterLectotypeParams?prodId=${prodId}`, {
			data,
		})
	}
	/**
	 * 根据分类ID获取产品（更多）
	 */
	static async GetProductsByClass(Page = 1, Size = 10, CID = 0, State = 1){
		return await DataService.get(`${AppConfig.lectoHost}/api/Prod/GetProductsByClass?Page=${Page}&Size=${Size}&CID=${CID}&State=${State}`)
	}
	/**
	 * 搜索产品
	 */
	static async SearchProducts(Page = 1, Size = 10, Keyword = '', State = 1){
		return await DataService.get(`${AppConfig.lectoHost}/api/Prod/SearchProducts?Page=${Page}&Size=${Size}&Keyword=${Keyword}&State=${State}`)
	}
	
	/**
	 * 产品logo图
	 */
	static prodLogo(prodId, w = 300, h = 300){
		return `${AppConfig.lectoHost}/images/products/${prodId}/logo_${w}x${h}.jpg?v=${new Date().getTime()}`
	}
	static prodImage(prodId, key, imageId){
		return `${AppConfig.lectoHost}/images/products/${prodId}/${key}/${imageId}.jpg?v=${new Date().getTime()}`
	}
	
	/**
	 * 获取产品图片id
	 * @param {Object} prodId
	 */
	static async prodPic(prodId){
		return await DataService.get(`${AppConfig.lectoHost}/api/ProdPic/${prodId}?v=${new Date().getTime()}`)
	}
	
}
