<template>
  <div id="app">
    <a-config-provider :locale="locale" v-if="init">
		  <router-view />
	  </a-config-provider>

    <div class="app-skeleton flex-center" v-if="!init">
      <a-spin size="large" :tip="compDetail.comp_out_name" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {message} from 'ant-design-vue'
import {Event, EventEnum} from '@yqmodel/module'
import { LayoutService } from '@/service'
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      init: false,
    };
  },
  computed: {
      ...mapState({
          compDetail:state => state.comp.compDetail,
      }),
  },
  components: {},
  mounted() {
    Event.remove(EventEnum.SHOW_MESSAGE_INFO, this.messageInfo);
    Event.on(EventEnum.SHOW_MESSAGE_INFO, this.messageInfo);

    Event.remove(EventEnum.SHOW_MESSAGE_ERR, this.messageError);
    Event.on(EventEnum.SHOW_MESSAGE_ERR, this.messageError);

    this.appInit();
  },
  methods: {
    ...mapActions({
      getWxalocaleData: 'comp/getWxalocaleData',
    }),
    async appInit() {
      // 语言
      const localeRes = await this.getWxalocaleData();
      this.$i18n.locale = localeRes.locale;

      // layout全局初始化
      await LayoutService.layoutGlobalInit();

      document.title = this.$t('document.title');
      this.init = true;
    },
    messageError(errMsg) {
      message.error(errMsg);
    },
    messageInfo(info) {
      message.info(info);
    }
  }
}
</script>

<style lang="less">
body{
  background-color: #ffffff;
}
.app-skeleton{
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #ffffff;
  .ant-spin-text{
    line-height: 60px;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
