<template>
    <div class="yq-modal-view" v-if="show">
        <div class="yq-modal-view-bg"></div>
        <div
            class="yq-modal-body"
        >
            <div class="yq-modal-close flex-center" @click="close"><a-icon type="close" style="font-size: 20px;color: #061A23;" /></div>
            <div class="yq-modal-title">{{title}}</div>
            <div class="yq-modal-content" :style="{width: width, height: height}">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props: {
            width: { default: '600px' },
            title: { default: '' },
            height: { default: 'auto' },
            status: { default: false, }
        },
        data() {
            return {
                show: false,
            }
        },
        watch: {
			status: {
				handler(new__, old__) {
                    this.show = new__;
				},
				immediate: true,
                deep: true
			},
		},
        mounted() {
            
        },
        methods:{
            open() {
                this.show = true;
            },
            close() {
                this.show = false;
                this.$emit('close')
            }
        }
    }
</script>

<style lang="less">
    .yq-modal-view{
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .yq-modal-view-bg{
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(6, 26, 35, 0.4);
    }
    .yq-modal-body{
        position: relative;
        z-index: 2;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: @shadow-main;
    }
    .yq-modal-title{
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 16px;
        font-size: 16px;
        color: #061A23;
    }
    .yq-modal-close{
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>