<template>
	<a-button
		type="primary"
		class="locale-switch"
		@click="change"
		v-if="localeWitch"
	>
		<img :src="switchIcon" mode="" />
		<span v-if="locale == 'zh-Hans'">En</span>
		<span v-if="locale == 'en'">中</span>
	</a-button>
</template>

<script>
	import {mapState} from 'vuex'
	import {UtilLib} from '@/libs'

	export default {
		data() {
			return {
				switchIcon: 'https://static-1311029656.cos.ap-nanjing.myqcloud.com/xcx/icon/switch.png',
			}
		},
		computed: {
			...mapState({
				localeWitch: state => state.comp.localeWitch,
				locale: state => state.comp.locale,
				switchMode: state => state.comp.switchMode,
				switchUrl: state => state.comp.switchUrl,
			}),
		},
		methods: {
			change() {
				if (this.switchMode == 'url') {
					let urlStr = location.href.replace(location.origin, '');
					let sessionData = {};
					for (let index = 0; index < sessionStorage.length; index++) {
						const key = sessionStorage.key(index);
						const value = sessionStorage.getItem(key);
						sessionData[key] = value;
					}
					if (location.href.includes('/productslecto/')) {
						urlStr = UtilLib.delurlparam(urlStr, 'urlParams');
					};
					const session = JSON.stringify(sessionData);
					window.location.href = `${this.switchUrl}${urlStr}${urlStr.includes('?') ? '&' : '?'}session=${encodeURIComponent(session)}`;
					return;
				}
				const localeSet = this.locale == 'en' ? 'zh-Hans' : 'en';
                this.$i18n.locale = localeSet;
				this.$store.dispatch('comp/setWxalocale', localeSet);
			}
		}
	}
</script>

<style lang="less" scoped>
	.locale-switch{
		border-radius: 100px;
		margin-right: 16px;
		background-color: #ffffff!important;
		font-size: 14px;
		color: #60708F!important;
		img{
			width: 12px;
			height: 11px;
			margin-right: 4px;
		}
	}
</style>