import axios from "axios";
import {isDesktop} from '@yqmodel/module'
import { UserService } from "@/service"
import { AppConfig } from "@/libs"
import Event, {EventEnum} from '@/EventEmeitter'

export const UserStore = {
    namespaced: true,
    state: {
        userInfo: UserService.user(),
    },
	getters: {
		isLogin(state) {
			if (isDesktop) return true;
			const accessToken = state.userInfo.accessToken || {};
			if (!accessToken.token) {
				return false;
			}
			if ( new Date(accessToken.expires).getTime() <= new Date().getTime() ) {
				return false;
			}
		  	return true;
		}
	},
    mutations: {
        checkLogin(state, isLogin = true) {
			if (isDesktop) return false;
			const accessToken = state.userInfo.accessToken || {};
			if (!accessToken.token) {
				isLogin && Event.emit(EventEnum.LOGIN_OPEN);
				return false;
			}
			if ( new Date(accessToken.expires).getTime() <= new Date().getTime() ) {
				isLogin && Event.emit(EventEnum.LOGIN_OPEN);
				return false;
			}
		  	return true;
		},
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        }
    },
    actions: {
		checkUserStatus(context) {
			if (isDesktop) return false;
			const token = UserService.getToken();
			axios({
				url: `${AppConfig.lectoHost}/api/User/MyRecordCount`,
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}` || '',
				},
			}).catch((err) => {
				if (err.response.status === 401 || err.response.status === 403) {
					UserService.clearUserInfo();
					context.commit('setUserInfo', {})

					if (AppConfig.compKey == 'atlas'){
						context.dispatch('loginOnlyPhone', '13888888888');
					}
				}
			})
		},
		login(context, formData) {
			return new Promise((resolve) => {
				const { phone, code } = formData;
				UserService.login(phone, code).then(res => {
					if (res.code === 0) {
                        context.commit('setUserInfo', res.data)
						UserService.setUserInfo(res.data);
						resolve(true);
					} else {
						resolve(false);
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		loginOnlyPhone(context, phone) {
			return new Promise((resolve) => {
				UserService.LoginOnlyPhone(phone).then(res => {
					if (res.code === 0) {
                        context.commit('setUserInfo', res.data)
						UserService.setUserInfo(res.data);
						resolve(true);
					} else {
						resolve(false);
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		userUpdate(context, option){
			UserService.update(option.email).then(res => {
				if (res.code === 0) {
					let userInfo = UserService.user();
					userInfo.email = option.email;
					UserService.setUserInfo(userInfo);
					context.commit('setUserInfo', userInfo)
				}
			});
		},
		logOut() {
			UserService.clearUserInfo();
			window.location.replace('/');
		}
	},
}
