<template>
    <div
        class="footer-view flex-center"
        :class="beian ? 'show' : ''"
        :style="{ transform: $route.name == 'Home' ? 'translateX(-2%)' : 'translateX(0)' }"
        v-if="!isDesktop"
    >
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012395" style="display:inline-block;text-decoration:none;">
          <img style="width: 14px;height: 14px;float:left;margin-top: 3px;" src="/static/images/icon/beian.png"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;font-size: 12px;">浙公网安备33010602012395号</p>
        </a>
        <a target="_blank" href="https://beian.miit.gov.cn/" style="margin-left: 16px;display:inline-block;text-decoration:none;"><span style="font-size: 12px;">浙ICP备2022013726号-1</span></a>
    </div>
</template>

<script>
import {isDesktop} from '@yqmodel/module'
import {AppConfig} from '@/libs'

export default {
    data() {
        return {
            isDesktop,
            beian: AppConfig.beian
        }
    },
    mounted() {
        
    },
}
</script>

<style lang="less" scoped>
    .footer-view{
        width: 100%;
        height: 24px;
        display: none;
        align-items: center;
        line-height: 24px;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 900;
        &.show{
            display: flex;
        }
        a{
            color: @text-color;
        }
    }
</style>