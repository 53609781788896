
import {AppConfig} from '@/libs/index.js';
import {DataService} from '@/service/service.js';

export class LectoCommonService {

	// 工况选型
	/**
	 * 计算初始化
	 */
	static async CalculationInit(module = '', data = {}) {
		let url = `${AppConfig.lectoHost}/api/${module}/CalculationInit`;
		Object.keys(data).forEach((item, index) => {
			url = url + (index === 0 ? '?' : '&') + item + '=' + data[item];
		})
		return await DataService.get(url);
	}

	/**
	 * 计算生成型号列表
	 * @param {Object} data
	 */
	 static async Calculation(module = '', data) {
		let url = `${AppConfig.lectoHost}/api/${module}/Calculation`;
		Object.keys(data).forEach((item, index) => {
			url = url + (index === 0 ? '?' : '&') + item + '=' + data[item];
		})
		return await DataService.get(url);
	}
	
	/**
	 * 获取选型参数，初始化
	 * module：Commutator
	 */
	static async GetLectotypeParams(module = '', option = {}) {
		let urlStr = '';
		Object.keys(option).forEach((item, index) => {
			urlStr = `${urlStr}${(index === 0 ? '?' : '&')}${item}=${option[item]}`
		})
		return await DataService.get(`${AppConfig.lectoHost}/api/${module}/GetLectotypeParams${urlStr}`);
	}
	
	/**
	 * 过滤选型参数
	 * module：Commutator
	 */
	static async FilterLectotypeParams(module = '', filters = [], option = {}) {
		const filterKey = {
			Module: 'moduleFilters',
			ScrewLift: 'liftFilters',
			RSKFReducer: 'reducerFilters',
		};
		const dataFilters = filters.map(item => {
			const paramType = item.paramTypeOrgin ? item.paramTypeOrgin : item.paramType;
			return { paramName: item.paramName, paramType, value: item.value }
		});
		return await DataService.post(`${AppConfig.lectoHost}/api/${module}/FilterLectotypeParams`, {
			data: {
				[filterKey[module] || 'filters']: dataFilters,
				...option,
			},
		})
	}
	
	/**
	 * 确定选型参数
	 * module：Commutator
	 */
	static async DetermineSkuParams(module = '', skuID, aloneAndInputFilters = [], option = {}) {
		const skuIDKey = {
			Module: 'moduleSkuID',
			ScrewLift: 'liftSkuID',
			RSKFReducer: 'reducerSkuID',
		};
		const filtersKey = {
			ScrewLift: 'inputFilters',
			RSKFReducer: 'aloneFilters',
		};
		const aloneAndInputDatas = aloneAndInputFilters.filter(item => {
			const paramType = item.paramTypeOrgin ? item.paramTypeOrgin : item.paramType;
			return [1,5,6,7].includes(paramType);
		}).map(item => {
			const paramType = item.paramTypeOrgin ? item.paramTypeOrgin : item.paramType;
			return { paramName: item.paramName, paramType, value: item.value };
		});
		const data = {
			[skuIDKey[module] || 'skuID']: skuID,
			[filtersKey[module] || 'aloneAndInputFilters']: aloneAndInputDatas,
			appID: 'pc',
		}

		Object.keys(option).forEach(item => {
			option[item] && (data[item] = option[item]);
		})
		return await DataService.post(`${AppConfig.lectoHost}/api/${module}/DetermineSkuParams`, {
			data,
		})
	}
	
}

