<template>
	<yq-modal
        :title="title"
        width="500px"
        ref="yqModalRef"
        height="140px"
	>
		<div class="progress-modal">
			<div class="progress-div">
                <a-progress :percent="percentage" status="active" :strokeColor="theme.main" />

				<div class="desc-txt" v-if="desc">{{  desc  }}</div>
			</div>
		</div>
	</yq-modal>
</template>

<script>
	import {Event as ModelEvent, EventEnum as ModelEventEnum} from '@yqmodel/module'
	import Event, {EventEnum} from '@/EventEmeitter'
	import theme from '@/mixins/theme'
	let processInterval = null;
	
	export default{
		data(){
			return {
				title: this.$t('loading.loading') + '...',
				desc: '',
				show: false,
				percentage: 0,
				interTime: 3000 * 2 / 50,

				halfTime: 3000,
				step: 3,
				
				animationData: {},
			}
		},
		mixins: [theme],
		mounted() {
			ModelEvent.remove(ModelEventEnum.PROGRESS_OPEN);
			ModelEvent.on(ModelEventEnum.PROGRESS_OPEN, this.open);
			ModelEvent.remove(ModelEventEnum.PROGRESS_CLOSE);
			ModelEvent.on(ModelEventEnum.PROGRESS_CLOSE, this.stop);

			Event.remove(EventEnum.PROGRESS_OPEN);
			Event.on(EventEnum.PROGRESS_OPEN, this.open);
			Event.remove(EventEnum.PROGRESS_CLOSE);
			Event.on(EventEnum.PROGRESS_CLOSE, this.stop);
		},
		methods: {
			open(option = {}) {
				option.title && (this.title = option.title);
				this.desc = option.desc || '';
				if (option.halfTime) {
					this.halfTime = option.halfTime;
					this.interTime = option.halfTime * 2 / 50;
				}
				option.step && (this.step = option.step);
				this.startProcess();
				this.$refs.yqModalRef.open();
			},
			startProcess() {
				processInterval = setInterval(() => {
					if (this.percentage < 50) {
						this.percentage = this.percentage + 2;
					} else if (this.percentage >= 50) {
						this.percentage = this.percentage + this.step;
					}
					if (this.percentage >=98) {
						clearInterval(processInterval);
						this.percentage = 98;
					}
				}, this.interTime)
			},
			stop() {
				processInterval && clearInterval(processInterval);
				this.percentage = 100;
				this.cancel();
			},
			cancel(type = '') {
				this.$emit('cancel', type);
				setTimeout(() => {
					this.percentage = 0;
					this.$refs.yqModalRef.close();
				}, 600)
			}
		}
	}
</script>

<style scoped lang="less">
	.progress-modal{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.title{
		text-align: center;
		font-size: 28px;
		color: #061A23;
		margin-bottom: 36px;
	}
	.desc-txt{
		text-align: center;
		line-height: 60px;
		font-size: 14px;
	}
	.progress-div{
		width: 430px;
		margin: 0 auto;
		position: relative;
	}
</style>