let Renderer = null, ipc = null;
const nowWindow = window;
const parentWindow = window.parent;
Renderer = (nowWindow.require && nowWindow.require('electron')) || nowWindow.electron || {};
if (!Renderer.ipcRenderer) {
    try {
        Renderer = (parentWindow.require && parentWindow.require('electron')) || parentWindow.electron || {};
    }
    catch (error) { }
}
ipc = Renderer.ipcRenderer || null;

export class ElectronLib {

	static check() {
        return ipc ? true : false;
    }

    static desktopInfo() {
        if (!ipc) return null;
        return ipc.sendSync('getDesktopConfig')
    }
	
}
