import {ClassKey} from '@yqmodel/module'
import {UtilLib} from './util'

/**选型相关配置 */
export const LectoConfig = {
	/**需要传递motorSkuID参数的类目 */
	motorSkuIDWhite: [
		ClassKey.Module.class_module,
		ClassKey.ElectricCylinder.class_module,
	],
	/**需要传递cid参数的类目 */
	cidWhite: [
		ClassKey.GearReducer.class_module,
		ClassKey.ComLectotype.class_module,
	],
	workingParamsWhite: [
		ClassKey.BallScrew.class_module,
		ClassKey.BallRail.class_module,
	],
	/**已开发完成的工况选型id部分 */
	calcWhite: [2000000, 2000001, 2000002, 3000000, 3, 300, 20, 22, 23, 28, 30, 31, 120, 121],
	/**已开发完成的参数选型id部分 */
	paramsWhite: [3,4,20,22,23,24,25,27,28,30,31,33,35,300],
};

export class LectoLib {
	
	/**
	 * 合并初始化参数和过滤后的参数数据，用于前端只显示过滤后的参数
	 * @param {Object} initParams 初始化的参数
	 * @param {Object} filterParams 过滤后的参数
	 */
	static paramsMerge(initParams = [], filterParams = []) {
		if (filterParams.length <= 0) {
			return initParams;
		}
		return initParams.map(item => {
			const findOne = filterParams.find(v => v.paramName === item.paramName) || {};
			const values = findOne.values ? findOne.values : (item.valueTmp || []);
			const itemData = {
				...item,
				values,
			}
			findOne.children && (itemData.children = findOne.children || []);
			return itemData;
		})
	}

	/**
	 * 删除checkparams不在params中的数据项
	 * @param {Object} checkParams
	 * @param {Object} params
	 */
	static deleteCheckParamsByParams(checkParams = {}, params = []) {
		let checkData = JSON.parse(JSON.stringify(checkParams));
		// 是否有清除操作
		let isDelete = false;
		Object.keys(checkData).forEach(paramNameItem => {
			const findOne = params.find(v => v.paramName == paramNameItem);
			// 如果已选中但是params选型数据中没有需要删除选中
			if (checkData[paramNameItem] && !findOne) {
				isDelete = true;
				delete checkData[paramNameItem]
			}
			// 如果已选中但是params选型数据中values无选项
			if (![0,1].includes(findOne.paramType) && checkData[paramNameItem] && findOne.values && !findOne.values.includes(checkData[paramNameItem].value)) {
				isDelete = true;
				delete checkData[paramNameItem]
			}
		})
		return { checkParams: checkData, isDelete };
	}
	
	/**
	 * 根据新的参数、界面显示的参数来进行已选参数的过滤
	 * @param {Object} params 新的的参数数组
	 * @param {Object} checkParams 选中的参数object
	 * @param {Object} showParams 界面显示的参数数组
	 */
	static filterCheckByParams(params = [], checkParams = {}, operation = 'detault') {
		let checkData = JSON.parse(JSON.stringify(checkParams));
		// 默认选中的参数
		let checkOnes = [];
		if (operation == 'reverse') {
			return { checkParams: checkData, checkOnes };
		}
		// 首先根据参数数组过滤，values中不存在已选中的值时，清除选中
		params.forEach((item, index) => {
			if ([0,1].includes(item.paramType)) return false;
			item.values = item.values || [];
			if (!checkData[item.paramName] && item.values.length === 1) {
				checkOnes.push(item);
				checkData[item.paramName] = {
					paramName: item.paramName,
					displayName: item.displayName,
					paramType: item.paramType,
					value: item.values[0],
				}
			}
		})
		return { checkParams: checkData, checkOnes };
	}
	
	/**
	 * 返回过滤后的选型参数，values值空的
	 * params 选型参数
	 */
	static lectoParamsArr(params = []) {
		return params.filter(item => ([0].includes(item.paramType) || item.values.length > 0));
	}

	/**
	 * 输入类型，可输入值数量少于9时解析成可选
	 */
	static resetParamsType(params = []) {
		return params.map(item => {
			if (item.paramType === 1 || item.paramTypeOrgin === 1) {
				const { min, max, step } = UtilLib.paramsInputRule(item);
				if (!min) return item;
				const stepCount = parseInt((max - min)/Number(step), 10) + 1;
				// console.log(stepCount)
				if (stepCount >= 9) return item;
				let values = [`${min}`];
				for (var i = 1; i < stepCount; i++) {
					values.push(`${min + step * i}`);
				}
				return {
					...item,
					values,
					paramType: 2,
					paramTypeOrgin: 1,
				}
			} else {
				return item;
			}
		});
	}
	
}

