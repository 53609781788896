import {AppConfig} from '@/libs/index.js';
import {DataService} from './service'

const layoutData = {};

export class LayoutService {
	
	static async layoutGlobalInit() {
		const types = ['pc_product_border_color','pc_product_border_width'];
		const res = await DataService.get(`${AppConfig.nodeHost}/share/comp/layoutInfos?comp_key=${AppConfig.compKey}&types=${types.toString()}`);
		Object.keys(res.data || {}).forEach((item) => {
			layoutData[item] = res.data[item];
		})
		console.log(layoutData);
		return layoutData;
	}
	
	/**
	 * layoutInfo
	 * 返回的直接是反序列化后的数据
	 */
	static async layoutInfo(type) {
		if (!type) return null;
		if (layoutData[type]) return layoutData[type];
		const res = await DataService.get(`${AppConfig.nodeHost}/share/comp/layoutInfo?comp_key=${AppConfig.compKey}&type=${type}`);
		layoutData[type] = res.data || null;
		return layoutData[type];
	}
	
}
