import axios from "axios";
import dayjs from "dayjs";
import { CompService } from "@/service"
import { UtilLib, StorageLib, UserEnum } from '@/libs'

export const CompStore = {
  namespaced: true,
  state: {
    compDetail: {},
    banners: [],
    logo: '',
    logo_square: '',
    wx_share_logo: '',
    theme: {},

    // 语言
		locale: '',
		// 是否开启语言切换
		localeWitch: false,
    // default、url
    switchMode: 'default',
    switchUrl: '',

    layout: {},
  },
  mutations: {
    setCompConfig(state, resData) {
      state.compDetail = resData;
      state.logo = UtilLib.nodeImgUrl(resData.logo);
      state.logo_square = UtilLib.nodeImgUrl(resData.logo_square);
      state.wx_share_logo = UtilLib.nodeImgUrl(resData.wx_share_logo);

      // 设置站点link icon
      // var link = document.createElement("link");//先获取link标签对象
      // link.rel = "shortcut icon";//设置rel类型
      // link.href = state.logo_square;//设置href链接
      // document.head.appendChild(link);//输出修改
    },
    setTheme(state, theme = {}) {
      state.theme = JSON.parse(JSON.stringify(theme))
    },
    setState(state, obj = {}){
			state = Object.assign(state, obj);
		},
  },
  getters: {

  },
  actions: {
    /**
		 * 设置语言
		 * @param {Object} context
		 * @param {Object} locale
		 */
		async setWxalocale(context, locale) {
			context.commit('setState',{
				locale,
			});
			// StorageLib.set('locale', locale);
		},
    /**
		 * 获取应用语言
		 * @param {Object} context
		 */
		async getWxalocaleData(context) {
			const res = await CompService.getLocaleData();
			let layout = res.data || { locale: 'zh-Hans', switchBtn: false };
      let otherData = {};
      layout.switchMode && (otherData.switchMode = layout.switchMode)
      layout.switchUrl && (otherData.switchUrl = layout.switchUrl)
			// 设置应用语言
			context.commit('setState',{
				locale: layout.locale,
				localeWitch: layout.switchBtn,
        ...otherData,
			});
			return layout;
		},

    async getPcLayout(context) {
      const res = await CompService.getCompPclayout();
      console.log(res.data)
			context.commit('setState',{
				layout: res.data,
			});
			return res.data;
    },


    async getCompDetail(context) {
      const res = await CompService.getCompDetail();
      if (res.errcode === 0) {
        StorageLib.set(UserEnum.COMP_INFO, res.data);
        context.commit('setCompConfig', res.data)
      }
    },

    async getCompPctheme(context) {
      const theme = await CompService.getCompPctheme();
      context.commit('setTheme', theme);

      axios.get('/static/customtheme.css?v=' + dayjs(dayjs().format('YYYY-MM-DD HH')).valueOf()).then(themeRes => {

        const templateFn = {
          handleRgba: (opacity = 1) => {
            return UtilLib.hexToRgba(theme.handle, opacity).rgba;
          },
          handleHex: (opacity = 1) => {
            return UtilLib.hexToRgba(theme.handle, opacity).hex;
          },
          handleEmphasisHex: (opacity = 1) => {
            return UtilLib.hexToRgba(theme.handleEmphasis, opacity).hex;
          }
        }

        let cssReg = /{{(.*?)}}/g
        let cssData = themeRes.data.replace(cssReg, (item, key) => {
          key = key.trim();
          if (key.startsWith('fn_')) {
            const [type, fnName] = key.split('_');
            return templateFn[fnName](...key.split('_').slice(2))
          }
          return theme[key.trim()]
        })

        const style = document.createElement("style");
        style.type = "text/css";
        style.innerHTML = cssData;
        window.document.head.appendChild(style);

      })
    },
  },
};