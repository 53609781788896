import { render, staticRenderFns } from "./locale-switch.vue?vue&type=template&id=14680f14&scoped=true&"
import script from "./locale-switch.vue?vue&type=script&lang=js&"
export * from "./locale-switch.vue?vue&type=script&lang=js&"
import style0 from "./locale-switch.vue?vue&type=style&index=0&id=14680f14&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14680f14",
  null
  
)

export default component.exports