import md5 from 'md5';
import {message} from 'ant-design-vue'
import {AppConfig,StorageLib} from '@/libs/index.js';
import {DataService} from './service.js'

export class RecordDownloadService {
	
	static getPath(url, downType = 'local') {
		if (downType == 'cos') {
			return `${AppConfig.bomCos}/${url}`;
		}
		return `${AppConfig.lectoHost}${url}`
	}
	
	/**
	 * 我的下载记录
	 */
	static async GetList(page = 1, size = 15, DownloadType = ''){
		let url = `${AppConfig.lectoHost}/api/RecordDownload/GetList?page=${page}&size=${size}`;
		DownloadType && (url = `${url}&DownloadType=${DownloadType}`)
		return await DataService.get(url);
	}
	
	/**
	 * 下载检查
	 */
	static async CheckDownload(selectionID, orderParams = []) {
		if (!selectionID) {
			console.error('selectionID不能为空');
			return false;
		}
		return await DataService.post(`${AppConfig.lectoHost}/api/RecordDownload/CheckDownload`, {
			data: {
				selectionID,
				orderParams,
				appID: 'pc',
			}
		});
	}
	
	/**
	 * 通知服务端生成模型
	 * 1:外形图纸,2:模型,3:打包下载 (默认2)
	 */
	static async CreateModel(selectionID, downloadType = 2, orderParams = []) {
		if (!selectionID) {
			console.error('selectionID不能为空');
			return false;
		}
		return await DataService.post(`${AppConfig.lectoHost}/api/RecordDownload/CreateModel`, {
			data: {
				selectionID,
				downloadType,
				orderParams,
				appID: 'pc',
			}
		});
	}
	
	/**
	 * 发送下载
	 */
	static async SendDownload(selectionID, downloadType, orderParams = [], email = '', insert3d = false){
		if (!selectionID || downloadType === undefined) {
			console.error('selectionID|downloadType不能为空');
			return false;
		}
		const data = {
			selectionID,
			downloadType,
			insert3d: downloadType == 6 ? insert3d : false,
			orderParams,
			appID: 'pc',
		};
		if (downloadType === 103) {
			if (!email) {
				console.error('email不能为空');
				return false;
			}
			data.email = email;
			data.downloadType = 3;
		}

		const sessionKey = md5(JSON.stringify(data));
		if (StorageLib.sessionGet(sessionKey)) {
			const timedif = parseInt(new Date().valueOf() / 1000, 10) - Number(StorageLib.sessionGet(sessionKey));
			if (timedif < 20) {
				message.warning(`下载太频繁了，请${20 - timedif}秒后再试`)
				return { code: 1 };
			}
		}

		const res = await DataService.post(`${AppConfig.lectoHost}/api/RecordDownload/SendDownload`, {
			data
		}, { noShowErr: true });
		if (res.code == 0) {
			StorageLib.sessionSet(sessionKey, parseInt(new Date().valueOf() / 1000, 10));
		}
		return res;
	}
	
}
